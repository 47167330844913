import { deleteEvent, getEventbyId, putEventJoin } from "Api/event";
import DeleteIcon from "Assets/Icons/delete-icon.svg";
import MailIcon from "Assets/Icons/mail-icon.svg";
import PencilIcon from "Assets/Icons/pencil-icon.svg";
import ShareIcon from "Assets/Icons/share-icon.svg";
import SuccessIcon from "Assets/Icons/success-icon.svg";
import { ConfuseMan, HappyFullIcon, LinkIcon } from "Assets/Images";
import ImageDefault from "Assets/Images/default-ava.png";
import { CircularLoader, Modal, Pagination } from "Components";
import { Constants } from "Constants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "Themes";
import { imageLink } from "Utils/imageLink";
import { DetailWrapper } from "./index.style";

const EventDetail = () => {
  const navigation = useNavigate();
  const alertComponent = useAlert();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isloadingButton, setIsloadingButton] = useState(false);

  const [confirmModal, setConfirmModal] = useState({
    action: "",
    isOpen: false
  });

  const [dataDetail, setDataDetail] = useState({});

  const [attandences, setAttandences] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setmaxPage] = useState(1);

  const currentUrl = window.location.href?.split("/");
  const isDev = currentUrl.includes("webdev");

  // Function
  const handleNavigate = () => {
    navigation("/event");
  };

  const handleCategory = (data) => {

    let names = Array.isArray(data) && data?.map(item => item?.name);
    let result = names?.join(", ");

    return result;

  };

  const paginate = (data, pageNumber, pageSize = 6) => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data.slice(startIndex, endIndex);
  };

  // This is the function we wrote earlier
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }

  };

  const handleCopyClick = () => {
    const url = window.location.href;
    const domainSplit = url.split("/event")[0];

    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(`${domainSplit}/event/${id}`)
      .then(() => {
        // If successful, update the isCopied state value
        alertComponent.success("Tautan Acara Ceria telah berhasil disalin!");
      })
      .catch(() => {
        alertComponent.error("Maaf, Tautan Acara Ceria gagal untuk disalin!");
      });
  };

  const handleJoinEvent = async (boolean) => {
    const payload = {
      join: boolean
    };

    setIsloadingButton(true);
    await putJoinEvent(id, payload);

  };

  const handleDeleteEvent = () => {
    deleteDetailEvent(id);
  };

  const ImagePopUp = (action) => {
    switch (action) {
    case "join-event":
      return <img src={ConfuseMan} width={"250px"} height="auto" alt="popup" />;
    case "join-success":
      return <img src={HappyFullIcon} width={"120px"} height="auto" alt="popup" />;    
    case "unjoin-success":
      return <img src={SuccessIcon} width={"120px"} height="auto" alt="popup" />;  
    default:
      return <img src={ConfuseMan} width={"250px"} height="auto" alt="popup" />;
    }
  };

  const handleOpenModal = (action) => {

    setConfirmModal({ action, isOpen: true });

  };

  const handleSubmitModal = (action) => {

    switch (action) {
    case "join-event":
      return handleJoinEvent(true);
    case "unjoin-event":
      return handleJoinEvent(false);
    case "join-success":
      return window.location.reload();
    case "unjoin-success":
      return window.location.reload();
    case "delete-event":
      return handleDeleteEvent();
    default:
      return console.log("No action");
    }

  };

  // Function API
  const getDetailEvent = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await getEventbyId(id);
      setDataDetail(data);
      if (Array.isArray(data.attendances)) {
        setAttandences(data.attendances);
        setmaxPage(data.attendances.length / 5);
      }
      setIsLoading(false);
        
    } catch (error) {
      setIsLoading(false);
      alertComponent.error(error.response.data.message || "Error get event detail");
    }
  };

  const putJoinEvent = async (id, payload) => {
    try {
      
      const { data } = await putEventJoin(id, payload);

      if (payload.join) {
        getDetailEvent(id);
        alertComponent.success("Berhasil ikut acara ceria!");
        setConfirmModal({ action: "join-success", isOpen: true });
      } else {
        getDetailEvent(id);
        alertComponent.success("Batal mengikuti acara ceria!");
        setConfirmModal({ action: "unjoin-success", isOpen: true });
      }

      setIsloadingButton(false);

    } catch (error) {
      setConfirmModal({ action: "", isOpen: false });
      setIsloadingButton(false);
      alertComponent.error(error.response.data.message || "Error join event");
    }
  };

  const deleteDetailEvent = async (id) => {
    try {
      const { data } = await deleteEvent(id);
      alertComponent.success("Berhasil menghapus acara ceria!");
      setConfirmModal({ action: "", isOpen: false });
      navigation("/event");
    } catch (error) {
      alertComponent.error(error.response.data.message || "Error join event");
      setConfirmModal({ action: "", isOpen: false });
    }
  };

  // Component
  const renderHeader = () => (
    <div className="flex flex-row justify-between mb-4">
      <div className="mr-6">
        <p className="font-brandon_bold text-ileads-mainblue text-lg">Detail Acara Ceria</p>
        <div className="bg-ileads-yellow h-2 w-42" />
      </div>
  
      <button
        onClick={handleNavigate}
        className="bg-ileads-red text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap"
      >
        <span className="font-brandon_medium">Kembali</span>
      </button>
        
    </div>
  );
  
  const renderLoader = () => (
    <div className="flex items-center justify-center my-12">
      <CircularLoader size={30} color={Colors.yellow} unfilledColor={Colors.darkBlue} />
    </div>
  );

  useEffect(() => {
    
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });

    getDetailEvent(id);
  }, [id]);

  return (
    <>
      {renderHeader()}
      <div className="w-full flex gap-6">
        <DetailWrapper className="min-w-[67%] flex items-center relative">
          {
            isLoading ? renderLoader() : (
              <div className="flex justify-center items-center flex-col">
                <div className="flex gap-4 w-full max-w-[620px]">
                  <div className="w-1/2 max-w-[290px] h-auto aspect-[3/4] border flex justify-center items-center">
                    <img width={290} className="h-auto" alt="event-poster" src={imageLink(dataDetail.posterUrl)}/>
                  </div>
                  <div className="flex flex-col justify-center gap-1 w-1/2 max-w-[50%]">
                    <p className="text-ileads-darkblue text-2xl font-brandon_medium">{dataDetail.name}</p>
                    <p className="text-ileads-darkblue text-lg font-brandon_medium">
                      {
                                  `${moment(dataDetail.startTime).format("DD MMMM YYYY, HH:mm")} ${dataDetail.timezone?.toUpperCase()} s/d 
                                ${moment(dataDetail.endTime).format("DD MMMM YYYY, HH:mm")} ${dataDetail.timezone?.toUpperCase()}`
                      }
                    </p>
                    {dataDetail.locationDetail && 
                                <p className="text-ileads-darkblue text-lg font-brandon_medium truncate">
                                  {dataDetail.locationDetail}
                                </p>
                    }
                    <span className="flex items-center gap-1">
                      <img className="h-[10px]" height="10px" width="auto" src={LinkIcon} alt="link-icon"/>
                      <a href={dataDetail.location} target="_blank" className="text-ileads-darkblue text-lg underline font-brandon_medium truncate" rel="noreferrer">{dataDetail.location}</a>
                    </span>
                    <a href={`${isDev ? "/webdev" : ""}/feeds/event/${dataDetail.hashtag.replace("#", "")}`} className="text-ileads-darkblue text-2xl font-brandon_medium border-b-4 border-ileads-yellow w-fit max-w-[100%] truncate">
                      {dataDetail.hashtag}
                    </a>
                    <div className="flex mt-2 gap-3">

                      {
                        !dataDetail.isMe && dataDetail.isFinished && <div>
                          <button
                            type="button" 
                            disabled={true}
                            className="bg-ileads-lightgrey cursor-not-allowed w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                          >
                            <span className="font-brandon_medium">Terlewat!</span>
                          </button>
                        </div>
                      }

                      {!dataDetail.isMe && !dataDetail.isFinished && <div>
                        {!dataDetail.isJoin ? (
                          <button
                            type="button" 
                            disabled={isloadingButton}
                            className={`${isloadingButton ? "bg-ileads-lightgrey" : "bg-ileads-green"} w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap`}
                            onClick={() => handleOpenModal("join-event")}
                          >
                            <span className="font-brandon_medium">Ikut!</span>
                          </button>
                        ) : (
                          <button
                            type="button" 
                            disabled={isloadingButton}
                            className={`${isloadingButton ? "bg-ileads-lightgrey" : "bg-ileads-danger-3"} w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap`}
                            onClick={() => handleOpenModal("unjoin-event")}
                          >
                            <span className="font-brandon_medium">Batal Ikut!</span>
                          </button>
                        )}
                      </div>}
                      <button
                        type="button" 
                        className="flex items-center gap-2 bg-ileads-lightblue text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                        onClick={handleCopyClick}
                      >
                        <span className="font-brandon_medium">Bagikan</span>
                        <img className="h-4" height={16} width="auto" src={ShareIcon} alt="link-icon"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            dataDetail.isMe ? <div className="absolute top-6 right-6 flex gap-2">
              <button 
                className="flex justify-center rounded-full items-center bg-ileads-green py-2 px-4"
                onClick={() => navigation(`/event/edit/${id}`)}
              >
                <img src={PencilIcon} className="fill-white text-white" width={16} height={16} alt="edit-button"/>
                <p className="ml-1 font-brandon text-white">Edit</p>
              </button>
              <button 
                className="flex justify-center rounded-full items-center bg-ileads-red py-2 px-4"
                onClick={() => setConfirmModal({ action: "delete-event", isOpen: true })}
              >
                <img src={DeleteIcon} className="fill-white text-white" width={16} height={16} alt="edit-button"/>
                <p className="ml-1 font-brandon text-white">Hapus</p>
              </button>
            </div> : null
          }
          <Modal
            show={confirmModal.isOpen}
            onClose={() => setConfirmModal(data => ({ ...data, isOpen: false }))}
            title={confirmModal.action !== "" ? Constants.ModalConfirm.filter(data => data.status === confirmModal.action)[0]?.title : "title"}
            desc={confirmModal.action !== "" ? Constants.ModalConfirm.filter(data => data.status === confirmModal.action)[0]?.description : "dec"}
            icon={ImagePopUp(confirmModal.action)}
            buttons={
              <div className="flex gap-3">
                { ["join-event", "delete-event", "unjoin-event"].includes(confirmModal.action) && <button
                  type="button" 
                  className="bg-ileads-danger-3 text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-32"
                  onClick={() => setConfirmModal(data => ({ ...data, isOpen: false }))}
                >
                  Tidak
                </button>
                }
                    
                <button
                  type="button" 
                  className={`bg-ileads-green text-white text-center py-3 rounded-btn px-6 whitespace-nowrap ${confirmModal.action === "join-success" || confirmModal.action === "unjoin-success" ? "w-fit" : "w-32"}`}
                  onClick={() => handleSubmitModal(confirmModal.action)}
                >
                  {confirmModal.action === "join-success" || confirmModal.action === "unjoin-success" ?  "Kembali ke Detail Acara" : "Iya"}
                </button>
              </div>
            }
          />
        </DetailWrapper>

        <DetailWrapper>
          {
          isLoading ? renderLoader() : (
            <>
              <div className="flex flex-col items-center justify-center">
                <p className="font-brandon_medium text-2xl border-b-8 border-ileads-yellow w-fit">List Partisipan</p>
                <span className="flex gap-3 mt-3">
                  <p className="font-brandon_medium text-xl">Total Partisipan:</p>
                  <p className="font-brandon_medium text-xl text-ileads-lightblue">{Array.isArray(dataDetail.attendances) ? dataDetail.attendances.length : 0}</p>
                </span>
              </div>
              <div className="flex flex-col items-center text-center justify-center min-h-[312px] w-full">
                {
                  paginate(attandences, currentPage).map(attandance => (
                    <>
                      <span key={attandance.user.id} className="py-3 w-full">
                        <p className="font-brandon_medium text-xl">{attandance?.user?.fullname}</p>
                      </span>
                    </>
                  ))
                }
              </div>
              {attandences.length > 6 ? (
                <Pagination
                  className="mx-auto mt-3"
                  current={currentPage}
                  max={maxPage}
                  onNext={() => setCurrentPage(data => data + 1)}
                  onPrev={() => setCurrentPage(data => data - 1)}
                  onJump={(value) => setCurrentPage(value)}
                />
              ) : null}
            </>
          )
          }
        
        </DetailWrapper>
      </div>
      <DetailWrapper className="mt-6">
        {
           isLoading ? renderLoader() : (
            <div>
              <div className="p-3 rounded-xl bg-[#DFEFFC] border-ileads-mainblue border-2">
                <p className="text-sm mb-3">Event ini diselenggarakan oleh:</p>
                <div className="flex items-center gap-2 mb-3">
                  <div className="rounded-full border border-ileads-mainblue overflow-hidden">
                    <img 
                      className="h-6 w-6 object-cover" 
                      height={24} 
                      width={24} 
                      src={dataDetail?.user?.photo ? imageLink(dataDetail?.user?.photo) : ImageDefault} 
                      alt="link-icon"
                    />
                  </div>
                  <p className="font-brandon_medium text-2xl">{dataDetail.user.fullname}</p>
                  <a href={`mailto:${dataDetail.user.email}`}>
                    <img className="h-5" height={24} width="auto" src={MailIcon} alt="link-icon"/>
                  </a>
                </div>
                <p className="text-sm mb-3 whitespace-pre-line">{dataDetail.description}</p>
                <p className="text-sm">
                  <span className="text-ileads-darkblue text-sm font-brandon_medium">
                                Kategori:
                  </span>
                  {" "} {dataDetail?.categories && handleCategory(dataDetail?.categories)}
                </p>
                <p className="text-sm">
                  <span className="text-ileads-darkblue text-sm font-brandon_medium">
                                Tipe Kegiatan:
                  </span>
                  {" "}{dataDetail.type?.name}
                </p>
              </div>
            </div>
           )
        }
      </DetailWrapper>
      
    </>
  );
};

export default EventDetail;
