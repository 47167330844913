import styled from "styled-components";
import { Colors } from "Themes";

const ContentWrapper = styled.form`
  background-color: ${Colors.white};
  padding: 20px 20px 30px;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const PreviewWrapper = styled.div`
background-color: ${Colors.white};
  padding: 20px 20px 30px;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const DropdownContainer = styled.div`
  width: 100px;
  margin: 0;
  padding: 0;
`;

const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  border: 1px solid rgb(51 172 227) !important;
  border-radius: 0px !important;
`;

export {
  ContentWrapper,
  CheckboxInput,
  PreviewWrapper,
  DropdownContainer
};
