import { API, convertResponseApi } from "Api";
import { getListEvent, putEventJoin } from "Api/event";
import { getRequests } from "Api/feedback";
import { JournalSeeFeedback, Note, SadIcon } from "Assets/Images";
import { CircularLoader, Image, Modal, PostCard, Underlined } from "Components";
import Slider from "Components/Slider";
import { Constants } from "Constants";
import { useUsers } from "Context/UsersStateManagement";
import { groupBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "Themes";
import { ObjectUtils } from "Utils";
import { ImagePopUp } from "Utils/imagePopUp";
import { ContentWrapper, DateWrapper } from "./index.styles";
import "./Homepage.css";

export default function Homepage() {
  const navigate = useNavigate();
  const alertComponent = useAlert();
  const [journals, setJournal] = useState([]);
  const [feeds, setFeed] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const { user } = useUsers();
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [events, setEvents] = useState([]);

  const [confirmModal, setConfirmModal] = useState({
    action: "",
    id: 0,
    isOpen: false
  });

  useEffect(() => {
    getEvents({ isUpcoming: true });
    getJournal();
    getFeeds();
    getRequests({ limit: 3 })
      .then(({ data }) => setFeedbackList(data))
      .catch(console.error);
    return () => {
      /* on destroy */
    };
  }, []);

  const JournalTypeColors = {
    kpi: "yellow",
    "kpi coaching": "yellow",
    "project culture coaching": "purple",
    "project culture": "purple",
    others: "lightblue",
    coached: "green",
    other: "lightblue"
  };

  const getJournal = async () => {
    try {
      const {
        data: { data: responseData }
      } = await API.coachingJournalList(2, 1);
      setJournal(groupBy(responseData.journal, (journal) => moment(journal.journal_date).format("MM-DD-YYYY")));
    } catch (err) {
      setJournal({});
    } finally {
      setLoading(false);
    }
  };

  const getFeeds = async () => {
    try {
      const response = await API.userFeed();
      const data = convertResponseApi(response);
      setFeed((response.status === 200 && data) || []);
    } catch (error) {
      setFeed({});
    } finally {
      setIsLoading(false);
    }
  };

  const getEvents = async (params) => {
    setLoading(true);
    try {
      const { data } = await getListEvent(params);
      setEvents(data);
    } catch (error) {
      setEvents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const putJoinEvent = async (id, payload) => {
    try {
      
      const { data } = await putEventJoin(id, payload);

      if (payload.join) {
        alertComponent.success("Berhasil ikut acara ceria!");
        setConfirmModal({ action: "join-success", isOpen: true });
        getEvents({ isUpcoming: true });
      } else {
        alertComponent.success("Batal mengikuti acara ceria!");
        setConfirmModal({ action: "unjoin-success", isOpen: true });
        getEvents({ isUpcoming: true });
      }

    } catch (error) {
      setConfirmModal({ action: "", isOpen: false });
      alertComponent.error(error.response.data.message || "Error join event");
    }
  };

  const handleNavigateNotes = (journalId, isCoachee, learner, title, created_at, type) => () => {
    if (!isCoachee)
      navigate("/coaching-journals/notes", { state: { journalId, isCoachee, learner, title, created_at, type } });
    else
      navigate("/coaching-journals/notes/fill", {
        state: {
          journalId,
          isCoachee,
          learner,
          title,
          date: created_at,
          type
        }
      });
  };

  const handleNavigateJournal = () => () => {
    navigate("/coaching-journals");
  };

  const handleNavigateFeedback = (journalId) => () => {
    navigate("/coaching-journals/feedback", { state: { journalId } });
  };

  // * For handling event section
  const handleActionEvent = (event, id) => {
    setConfirmModal({
      action: event, 
      id: id,
      isOpen: true
    });
  };

  const handleJoinEvent = async (boolean) => {
    const payload = {
      join: boolean
    };

    await putJoinEvent(confirmModal.id, payload);

  };

  const handleSubmitModal = (action) => {
    switch (action) {
    case "join-event":
      return handleJoinEvent(true);
    case "unjoin-event":
      return handleJoinEvent(false);
    case "join-success":
      return window.location.reload();
    case "unjoin-success":
      return window.location.reload();
    default:
      return console.log("No action");
    }
  };

  const renderJournalDate = (journal) => (
    <div className="w-2/12">
      <DateWrapper>
        <div className="absolute right-5 bottom-5">
          <span className="font-brandon_bold text-2xl">
            {moment(new Date(journal)).locale("id").format("DD")}
            <br />
            {moment(new Date(journal)).locale("id").format("MMM")}
          </span>
        </div>
      </DateWrapper>
    </div>
  );

  const renderLoader = () => (
    <div className="flex items-center justify-center my-12">
      <CircularLoader size={30} color={Colors.yellow} unfilledColor={Colors.darkBlue} />
    </div>
  );

  const renderNoDataJournal = () => (
    <p className="font-brandon_bold text-ileads-mainblue text-center my-12">
      Belum ada coaching journal nih. Yuk segera tambahkan catatan coaching journal Anda!
    </p>
  );

  return (
    <div>
      <div className="flex flex-col relative">
        <ContentWrapper className="mt-4">
          {loading ? (
            renderLoader()
          ) : (
            <React.Fragment>
              {ObjectUtils.isEmpty(journals) ? (
                renderNoDataJournal()
              ) : (
                <React.Fragment>
                  {Object.keys(journals).map((journal, index) => (
                    <div className="flex mb-12" key={index}>
                      {renderJournalDate(journal)}
                      <div className="flex flex-col w-10/12">
                        {journals[journal].map((item, index) => (
                          <div key={index} className="flex flex-col">
                            <div className="flex w-full">
                              <div className=" pl-10 justify-between text-lg w-full">
                                <div
                                  className={"grid grid-cols-2 py-10 h-fit"}
                                  style={{
                                    borderBottom: "3px solid lightblue"
                                  }}
                                >
                                  <div className="flex flex-row">
                                    <div className="my-auto">
                                      <div
                                        className={`w-5 h-5 rounded-xl bg-ileads-${
                                          item.is_coachee ? "green" : JournalTypeColors[item.journal_type.toLowerCase()]
                                        }`}
                                      />
                                    </div>
                                    <div className="flex flex-col ml-8">
                                      <div className="overflow-hidden truncate max-w-xs">
                                        <span
                                          className={` text-ellipsis font-brandon_bold ${
                                            item.is_coachee
                                              ? "bg-ileads-green text-center rounded-2xl px-5 text-white"
                                              : "text-ileads-mainblue"
                                          }`}
                                        >
                                          {item.journal_title}
                                        </span>
                                      </div>
                                      <span>
                                        <span
                                          className={`text-ileads-${
                                            item.is_coachee
                                              ? "green"
                                              : JournalTypeColors[item.journal_type.toLowerCase()]
                                          } font-brandon`}
                                        >
                                          {item.is_coachee ? "Coached by" : "You coached"}
                                        </span>{" "}
                                        <div
                                          className={
                                            item.journal_learner.length > 2
                                              ? "flex text-left flex-wrap w-8/12"
                                              : "flex text-left flex-wrap w-full"
                                          }
                                        >
                                          {item.is_coachee ? (
                                            <span className="font-brandon text-ileads-mainblue">
                                              {item.coach_fullname}
                                            </span>
                                          ) : (
                                            <>
                                              {item.journal_learner.map((learner, indexLearner) => {
                                                return (
                                                  <div key={indexLearner}>
                                                    <span className="font-brandon text-ileads-mainblue">
                                                      {learner.jl_fullname}{" "}
                                                      {indexLearner !== item.journal_learner.length - 1 && ","}
                                                      &nbsp;
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  {item.is_coachee && !item.journal_learner[0].is_filled ? (
                                    <div className="flex justify-end items-center pr-10">
                                      <button
                                        tabIndex={0}
                                        onClick={() => {
                                          navigate("/coaching-journals/notes/fill", {
                                            state: {
                                              journalId: item.journal_id,
                                              isCoachee: item.is_coachee,
                                              learner: item.journal_learner,
                                              title: item.journal_title,
                                              date: item.journal_created_at,
                                              type: item.journal_type,
                                              fill: true
                                            }
                                          });
                                        }}
                                        className="flex justify-end font-brandon_bold text-center cursor-pointer w-full"
                                      >
                                        <div className="flex flex-row">
                                          <Image src={Note} alt="note" className="cursor-pointer mx-2" />
                                        </div>
                                        <span className="ml-4 font-brandon_medium transition-colors text-ileads-lightblue feedback hover:text-ileads-mainblue">
                                          Isi catatan
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="flex justify-end items-center pr-10">
                                      <button
                                        tabIndex={0}
                                        onClick={handleNavigateNotes(
                                          item.journal_id,
                                          item.is_coachee,
                                          item.journal_learner,
                                          item.journal_title,
                                          item.journal_created_at,
                                          item.journal_type
                                        )}
                                        className="flex font-brandon_bold text-center cursor-pointer"
                                      >
                                        <Image src={Note} alt="note" className="cursor-pointer mr-2" />
                                        <span className="ml-4 feedback font-brandon_medium transition-colors text-ileads-lightblue hover:text-ileads-mainblue">
                                          Lihat catatan
                                        </span>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </ContentWrapper>
        <button
          onClick={handleNavigateJournal()}
          className="bg-ileads-green text-white text-center py-4 rounded-btn w-fit px-8 items-center absolute"
          style={{ left: "40%", bottom: "-20px", zIndex: "49" }}
        >
          Lihat semua catatan
        </button>
      </div>

      <section className="p-5 bg-white rounded-2xl relative shadow-card mt-14" id="feed-container">
        <Underlined className="mx-auto">Recent Feedback Requests.</Underlined>
        <div className="grid gap-20 grid-flow-col place-content-center mt-5 mb-12">
          {feedbackList.length ? (
            feedbackList.map(({ rfu_user_from_id, user_fullname }) => (
              <span
                className="p-5 inline-block min-w-[150px] text-center bg-ileads-lightblue rounded-full text-white text-xl"
                key={rfu_user_from_id}
              >
                {user_fullname}
              </span>
            ))
          ) : (
            <div className="py-5">
              <img src={SadIcon} alt="" className="mx-auto mb-5" />
              <h5 className="font-brandon_bold text-ileads-mainblue">
                Saat ini belum ada recent feedback yang diperoleh untuk kamu!
              </h5>
            </div>
          )}
        </div>

        <Link
          to="/feedback"
          className="bg-ileads-green text-white text-center py-4 rounded-btn w-fit px-8 items-center absolute"
          style={{ left: "40%", bottom: "-20px", zIndex: "49" }}
        >
          Lihat semua feedback.
        </Link>
      </section>

      <section className="p-5 bg-white rounded-2xl relative shadow-card mt-14" id="feed-container">
        {feeds.map((feed) => (
          <PostCard post={feed} key={feed.feed_id} />
        ))}

        <button
          onClick={() => navigate("/feeds")}
          className="bg-ileads-green text-white text-center py-4 rounded-btn w-fit px-8 items-center absolute"
          style={{ left: "40%", bottom: "-20px", zIndex: "49" }}
        >
          Lihat semua feed
        </button>

        {isLoading && (
          <p className="flex items-center mt-20 mb-5 justify-center text-ileads-mainblue">
            <span className="flex h-6 w-6 items-center justify-center relative mr-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-ileads-lightblue opacity-75" />
              <span className="relative inline-flex rounded-full h-4 w-4 bg-ileads-mainblue" />
            </span>
            Loading more feeds...
          </p>
        )}
      </section>

      <section className="p-5 bg-white rounded-2xl relative shadow-card mt-14 w-full" id="feed-container">
        <Underlined className="mx-auto">Acara yang Akan Datang</Underlined>

        <div className="my-12">
          <Slider
            data={events}
            type="event"
            handleActionEvent={handleActionEvent}
          />
        </div>
        
        <Link
          to="/event"
          className="bg-ileads-green text-white text-center py-4 rounded-btn w-fit px-8 items-center absolute"
          style={{ left: "40%", bottom: "-20px", zIndex: "49" }}
        >
            Lihat Semua Acara Ceria
        </Link>

        <Modal
          show={confirmModal.isOpen}
          onClose={() =>
            setConfirmModal((data) => ({ ...data, isOpen: false }))
          }
          title={
            confirmModal.action !== ""
              ? Constants.ModalConfirm.filter(
                (data) => data.status === confirmModal.action
              )[0]?.title
              : "title"
          }
          desc={
            confirmModal.action !== ""
              ? Constants.ModalConfirm.filter(
                (data) => data.status === confirmModal.action
              )[0]?.description
              : "dec"
          }
          icon={ImagePopUp(confirmModal.action)}
          buttons={
            <div className="flex gap-3">
              {["join-event", "unjoin-event"].includes(
                confirmModal.action
              ) && (
                <button
                  type="button"
                  className="bg-ileads-danger-3 text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-32"
                  onClick={() =>
                    setConfirmModal((data) => ({ ...data, isOpen: false }))
                  }
                >
                  Tidak
                </button>
              )}

              <button
                type="button"
                className={`bg-ileads-green text-white text-center py-3 rounded-btn px-6 whitespace-nowrap ${
                  confirmModal.action === "join-success" ||
                  confirmModal.action === "unjoin-success"
                    ? "w-fit"
                    : "w-32"
                }`}
                onClick={() => handleSubmitModal(confirmModal.action)}
              >
                {confirmModal.action === "join-success" ||
                confirmModal.action === "unjoin-success"
                  ? "Kembali ke Home Page"
                  : "Iya"}
              </button>
            </div>
          }
        />
      </section>
    </div>
  );
}
