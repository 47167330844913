export const convertLinks = (inputText) => {
  // Regular expression to find HTTP/HTTPS links
  const regex = /(?<!<a\s+(?:[^>]*?\s+)?href=['"])(https?:\/\/\S+)(?![^<]*?(?:<\/a>|\/>))/gi;
  
  // Replace HTTP/HTTPS links with anchor tags
  const replacedText = inputText.replace(regex, "<a href=\"$1\">$1</a>");

  // Use dangerouslySetInnerHTML to render HTML content
  return replacedText;
};
