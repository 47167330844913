import Cup from "Assets/Icons/group-cup.svg";
import Jeruk from "Assets/Icons/group-jeruk.svg";
import Pizza from "Assets/Icons/group-pizza.svg";
import Shoe from "Assets/Icons/group-shoe.svg";
import Tree from "Assets/Icons/group-tree.svg";
import { HappyIcon, SadIcon, SickIcon, SurprisedIcon } from "Assets/Images";

import { positions, transitions } from "react-alert";

export const Constants = {
  AlertOptions: {
    offset: "30px",
    position: positions.TOP_RIGHT,
    timeout: 3000,
    transition: transitions.FADE,
    type: "error"
  },
  ErrorMessage: {
    GENERAL: "Please Try Again"
  },
  InputType: {
    PASSWORD: "password",
    TEXT: "text"
  },
  UserPositions: [
    {
      id: "cp",
      name: "Counter Part (CP)"
    },
    {
      id: "cl",
      name: "Culture Leader (CL)"
    },
    {
      id: "ca",
      name: "Culture Agent (CA)"
    },
    {
      id: "nwt",
      name: "Non Winning Team (NWT)"
    }
  ],
  CoachingJournalTypes: [
    {
      id: 0,
      key: "KPI coaching",
      name: "KPI coaching"
    },
    {
      id: 0,
      key: "Project Culture coaching",
      name: "Project Culture coaching"
    },
    {
      id: 0,
      key: "Others",
      name: "Others"
    }
  ],
  groupIcons: [
    {
      icon: Jeruk,
      name: "jeruk"
    },
    {
      icon: Cup,
      name: "cup"
    },
    {
      icon: Pizza,
      name: "pizza"
    },
    {
      icon: Shoe,
      name: "shoe"
    },
    {
      icon: Tree,
      name: "tree"
    }
  ],
  moodTracker: [
    {
      icon: HappyIcon,
      title: "Senang"
    },
    {
      icon: SadIcon,
      title: "Sedih"
    },
    {
      icon: SurprisedIcon,
      title: "Terkejut"
    },
    {
      icon: SickIcon,
      title: "Sakit"
    }
  ],
  coachingActivitiesPoint: [
    {
      title: "Tambah coaching journal.",
      value: "+2"
    },
    {
      title: "Penilaian feedback dari coachee.",
      value: "Average score"
    },
    {
      title:
        "Rata-rata dari self-reflection feedback dan feedback dari coachee.",
      value: "Average score"
    }
  ],
  feedPostPoint: [
    {
      title: "Membuat post di Feed.",
      value: "+2"
    },
    {
      title: "Jumlah komen dari rekan kerja lain di post Feed",
      value: "(+1/komen)"
    },
    {
      title:
        "Jumlah komen yang dikirimkan di post Feed yang dibuat oleh rekan  kerja lain",
      value: "(+1/komen)"
    }
  ],
  feedGuidlines: [
    {
      title: "Apa saja konten yang dapat dipublikasikan pada forum feed?",
      rules: [
        "Kegiatan yang menunjukan dirimu sebagai  seorangJUARA. Apapun kegiatan kamu  yang selaras dengan 4 prinsip Budaya Juara dapat di -post.",
        "Aktivitas rekan kerjamu yang selaras dengan 4 prinsip Budaya Juara.Saling meninggalkan komentar yang positif dan membangun kepada sesama rekan kerja. Karena mendapatkan dukungan dari rekan kerja tuh berarti banget, ya gak?!"
      ]
    },
    {
      title: "Apa saja yang tidak diperkenankan di dalam forum Feed?",
      rules: [
        "Semua konten yang berhubungan dengan SARA (Suku, Agama, Ras, dan Antargolongan) tidak diperbolehkan di dalam feed.",
        "Konten yang tidak mengandung 4 prinsip Budaya Juara dan menginspirasi orang lain untuk lebih baik."
      ],
      extra:
        "Konten atau komentar yang merendahkan, mengadu domba, dan fitnah tidak diperbolehkan. Berilah kritik atau komentar yang membangun sehingga diskusi menjadi lebih seru, menguntungkan, dan menginspirasi."
    },
    {
      title:
        "Team iLEAD berhak untuk menghapus konten dan memblokir user dalam jangka waktu tertentu. Yuk kita patuhi ground rules-nya!",
      rules: []
    }
  ],
  TimeZoneIndo: [
    {
      label: "WIB",
      value: "wib"
    },
    {
      label: "WITA",
      value: "wita"
    },
    {
      label: "WIT",
      value: "wit"
    }
  ],
  EventType: [
    {
      id: 0,
      label: "Online",
      value: "online"
    },
    {

      id: 1,
      label: "Offline",
      value: "offline"
    }
  ],
  CategoryEvent: [
    {
      id: 0,
      name: "E-Sport",
      value: false
    },
    {
      id: 1,
      name: "Olahraga",
      value: false
    },
    {
      id: 2,
      name: "Permainan (Games)",
      value: false
    },
    {
      id: 3,
      name: "Keterampilan & Seni",
      value: false
    },
    {
      id: 4,
      name: "Leisure",
      value: false

    },
    {
      id: 5,
      name: "Buku & Tulisan",
      value: false
    },
    {
      id: 6,
      name: "Film & Fotografi",
      value: false
    },
    {
      id: 7,
      name: "Makan & Minum",
      value: false
    },
    {
      id: 8,
      name: "Musik",
      value: false
    },
    {
      id: 9,
      name: "Acara Ceria Lainnya",
      value: false
    }
  ],
  TypeEvent: [
    {
      id: "0",
      label: "Kompetisi"
    },
    {
      id: "1",
      label: "Perkumpulan"
    },
    {
      id: "2",
      label: "Konser"
    },
    {
      id: "3",
      label: "Workshop"
    },
    {
      id: "4",
      label: "Kegiatan Seru Lainnya"
    }
  ],
  ModalConfirm: [
    {
      description: "Acara Ceria kamu akan dihapus. Apakah kamu yakin ingin membatalkan pembuatan Acara?",
      status: "cancel",
      title: "Kamu akan membatalkan pembuatan Acara."
    },
    {
      description: "Apakah kamu yakin ingin membatalkan perubahan Acara?",
      status: "cancel-edit",
      title: "Kamu akan membatalkan perubahan Acara."
    },
    {
      description: "Acara Ceria kamu akan dibuat. Apakah kamu yakin ingin membuat Acara Ceria?",
      status: "create",
      title: "Kamu akan membuat Acara Ceria."
    },
    {
      description: "Acara Ceria kamu akan diupdate. Apakah kamu yakin ingin mengupdate Acara Ceria?",
      status: "edit",
      title: "Kamu akan mengupdate Acara Ceria."
    },
    {
      description: "Acara Ceria telah Sukses dibatalkan!",
      status: "cancel-success",
      title: ""
    },
    {
      description: "Apakah kamu yakin ingin bergabung dengan acara ceria ini?",
      status: "join-event",
      title: "Ikut Acara Ceria"
    },
    {
      description: "Apakah Anda yakin ingin membatalkan partisipasi dalam acara ceria ini?",
      status: "unjoin-event",
      title: "Batal Mengikuti Acara Ceria"
    },
    {
      description: "Anda telah berhasil mengikuti Acara Ceria ini!",
      status: "join-success",
      title: "Sukses!"
    },
    {
      description: "Acara Ceria telah batal diikuti.",
      status: "unjoin-success",
      title: ""
    },
    {
      description: "Apakah kamu yakin ingin menghapus acara ini?",
      status: "delete-event",
      title: "Hapus Acara Ceria"
    }
  ],
  FilterSort: [
    {
      label: "Terbaru",
      value: "newest"
    },
    {
      label: "Terlama",
      value: "oldest"
    },
    {
      label: "A - Z",
      value: "a-z"
    },
    {
      label: "Z - A",
      value: "z-a"
    }
  ]

};
