/* eslint-disable */
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

/* UNPROTECTED */
import LandingPage from "Pages/Landing";
import LoginRegisterPage from "Pages/LoginRegister";
import OtpVerifyPage from "Pages/OtpVerify";
import ChangePassword from "Pages/Settings/ChangePassword/ChangePassword";
import CreateProfilePage from "Pages/CreateProfile";
import ForgotPasswordPage from "Pages/ForgotPassword";

/* MED */
import PrivateLayout from "Components/PrivateLayout/PrivateLayout";
import UnprotectedLayout from "Components/UnprotectedLayout";

/* PROTECTED */
// eslint-disable-next-line
import ChangePosition from "Pages/Settings/ChangePosition/ChangePosition";
import ChangeTeam from "Pages/Settings/ChangeTeam/ChangeTeam";

import CoachingJournalPage from "Pages/CoachingJournal";
import CoachingJournalNotesPage from "Pages/CoachingJournalNotes";
import CoachingJournalAddPage from "Pages/CoachingJournalAdd";
import JournalCoacheeNotes from "Pages/JournalCoacheeNotes";
import JournalCoacheeNotesFill from "Pages/JournalNotesFill";
import CoachingJournalFeedback from "Pages/CoachingJournalFeedback/CoachingJournalFeedback";
import CoachingJournalFeedbackAddPage from "Pages/CoachingJournalFeedbackAdd";

import Feedback from "Pages/Feedback";
import FeedbackCreate from "Pages/Feedback/Create";

import Commitment from "Pages/Commitment";
import CommitmentCreate from "Pages/Commitment/Create";

import Feed from "Pages/Feed";
import FeedDetail from "Pages/Feed/Detail";
import FeedGuideLine from "Pages/Feed/Guideline";
import FeedEvent from "Pages/Feed/Event"

import Brainstorms from "Pages/IdeaPools";
import DetailIdea from "Pages/IdeaPools/DetailIdea";
import BrainstormDetail from "Pages/IdeaPools/BrainstormDetail";

import JuaraQuiz from "Pages/JuaraQuiz";
import JuaraQuizDetail from "Pages/JuaraQuiz/Detail";

import MoodTracker from "Pages/MoodTracker";
import GetPoint from "Pages/GetPoint";
import LeaderBoard from "Pages/LeaderBoard";
import Homepage from "Pages/Homepage/Homepage";
import Questionnaire from "Pages/Questionnaire";
import Settings from "Pages/Settings/Settings";

import Event from "Pages/Event";
import EventCreate from "Pages/Event/Create";
import EventDetail from "Pages/Event/Detail";
import EventPreview from "Pages/Event/Preview";
import EventEdit from "Pages/Event/Edit";

export default function RootApp() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<UnprotectedLayout />}>
          <Route path="" element={<LandingPage />} />
          <Route path="login" element={<LoginRegisterPage page={"login"} />} />
          <Route path="register" element={<LoginRegisterPage page={"register"} />} />
          <Route path="otp-verification" element={<OtpVerifyPage />} />
          <Route path="create-profile" element={<CreateProfilePage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage page={"login"} />} />
        </Route>

        <Route path="" element={<PrivateLayout />}>
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/questionnaire" element={<Questionnaire />} />
          <Route path="/settings" element={<Settings />} />

          <Route path="/coaching-journals" element={<CoachingJournalPage />} />
          <Route path="/coaching-journals/notes" element={<CoachingJournalNotesPage />} />
          <Route
            path="/coaching-journals/notes/coachee"
            element={<JournalCoacheeNotes pageTitle="Lihat catatan coach." type="lihat catatan" />}
          />
          <Route path="/coaching-journals/notes/fill" element={<JournalCoacheeNotesFill />} />
          <Route path="/coaching-journals/add" element={<CoachingJournalAddPage />} />
          <Route path="/coaching-journals/feedback" element={<CoachingJournalFeedback />} />
          <Route path="/coaching-journals/feedback/add" element={<CoachingJournalFeedbackAddPage />} />

          <Route path="/feedback" element={<Feedback />} />
          <Route path="/feedback/:id" element={<FeedbackCreate isDetail />} />
          <Route path="/feedback/create/:id/:coach_id" element={<FeedbackCreate />} />
          <Route path="/feedback/improvement/:id" element={<CommitmentCreate isDetail />} />
          <Route path="/feedback/improvement/create/:id" element={<CommitmentCreate />} />

          <Route path="/feeds" element={<Feed />} />
          <Route path="/feeds/guideline" element={<FeedGuideLine />} />
          <Route path="/feeds/:feed_id" element={<FeedDetail />} />
          <Route path="/feeds/event/:hashtag" element={<FeedEvent />} />

          <Route path="/idea-pools" element={<Brainstorms />} />
          <Route path="/idea-pools/:group_id" element={<BrainstormDetail />} />
          <Route path="/idea-pools/:group_id/details/:idea_id" element={<DetailIdea />} />

          <Route path="/juara-quiz" element={<JuaraQuiz />} />
          <Route path="/juara-quiz/:id" element={<JuaraQuizDetail />} />

          <Route path="/mood" element={<MoodTracker />} />
          <Route path="/get-point" element={<GetPoint />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/settings/change-password" element={<ChangePassword />} />
          <Route path="/settings/change-teams" element={<ChangeTeam />} />
          <Route path="/settings/change-position" element={<ChangePosition />} />

          <Route path="/event" element={<Event />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route path="/event/create" element={<EventCreate />} />
          <Route path="/event/create/:id" element={<EventPreview />} />
          <Route path="/event/edit/:id" element={<EventEdit />} />
        </Route>
      </Routes>
    </Router>
  );
}
