export const transformTitle = (title) => {
  // Replace spaces and convert to title case
  let transformedTitle = title.replace(/\b(\w)(\w*)\b/g, (match, firstLetter, rest) => {
    return firstLetter.toUpperCase() + rest.toLowerCase();
  });

  // Remove spaces and non-alphanumeric characters
  transformedTitle = transformedTitle.replace(/\W+/g, "");

  return transformedTitle;
};
