/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { createComment, getFeedComments, getFeedDetail } from "Api/feed";
import { CommentCard, PostCard } from "Components";
import { useUsers } from "Context/UsersStateManagement";
import { useInfiniteScroll } from "Hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useNavigate, useParams } from "react-router-dom";

const FeedDetail = () => {
  const { feed_id } = useParams();
  const { user } = useUsers();
  const [feedDetail, setFeedDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [comment, setComment] = useState("");
  const [dataInfo, setDataInfo] = useState({});
  const [replyToId, setReplyToId] = useState("");
  const { loadMoreRef, page } = useInfiniteScroll(
    0,
    dataInfo.total_pages,
    isLoading
  );

  const commentField = useRef();

  const alertComponent = useAlert();
  const navigate = useNavigate();

  const currentUrl = window.location.href?.split("/");
  const isDev = currentUrl.includes("webdev");

  const handleReply = (selected) => {
    setReplyToId(selected.feed_comment_id);
    setComment(
      `${comment} @${selected.feed_comment_author_nickname} `.trimStart()
    );
    commentField.current.focus();
  };

  const handleSendComment = async ({ key, shiftKey }) => {
    if (!(key === "Enter" && !shiftKey)) return;

    try {
      setIsSending(true);
      setComment("");
      setReplyToId("");
      await createComment({
        feedId: feed_id,
        comment,
        replyToId
      });
      alertComponent.success("Komentar berhasil di kirim!");
    } catch (error) {
      alertComponent.show(error.response.data.message);
    } finally {
      setIsSending(false);
    }
    await getData();

    window.scrollTo({
      top: document.getElementById("container").offsetHeight,
      behavior: "smooth"
    });
  };

  const scrollHandler = useCallback(() => {
    const feedPost = document.getElementById("feed-post");

    if (
      Math.round(window.pageYOffset) >
        feedPost.offsetHeight + feedPost.offsetTop &&
      commentField?.current
    ) {
      commentField.current.classList.remove("shadow-2xl");
    } else {
      commentField.current.classList.add("shadow-2xl");
    }
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);

      const data = await getFeedDetail(feed_id, { baseLinkHashtag: `${isDev ? "/webdev" : "" }/feeds/event/` });
      const {
        meta,
        data: { comments }
      } = await getFeedComments(feed_id, {
        limit: 10,
        sort: "asc",
        page: page ? page : null
      });
      setDataInfo(meta);

      setFeedDetail({
        ...data,
        comments: feedDetail?.comments?.length
          ? [...feedDetail.comments, comments.pop()]
          : comments
      });
    } catch (error) {
      if (error.response.status === 404) {
        alertComponent.show("Tidak dapat menemukan feed!");
        navigate(-1);
      } else alertComponent.show(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getComment = async () => {
      if (page === 1) return;

      try {
        setIsLoading(true);
        const {
          meta,
          data: { comments }
        } = await getFeedComments(feed_id, {
          sort: "asc",
          limit: 10,
          page
        });
        setDataInfo(meta);

        setFeedDetail({
          ...feedDetail,
          comments: feedDetail?.comments?.length
            ? [...feedDetail.comments, ...comments]
            : comments
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getComment();
  }, [page]);

  useEffect(() => {
    getData();

    window.scrollTo({ top: 0 });

    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <section>
      <div className="flex items-center mb-4">
        <h1
          className="
            border-b-8
            text-xl
            border-ileads-yellow
            inline-block
            pl-1
            pr-2
          "
        >
          Feed
        </h1>
        <Link
          onClick={() => navigate(-1)}
          className="ml-5 text-sm py-2 px-4 bg-ileads-lightblue text-white rounded-full"
        >
          Kembali
        </Link>
      </div>
      <div className="p-5 bg-white rounded-2xl shadow-card" id="container">
        <PostCard
          canDelete={user.user_id === feedDetail.feed_author_id}
          post={feedDetail}
          id="feed-post"
        />

        <div className="flex overflow-y-auto flex-col gap-y-4">
          {feedDetail?.comments?.map((comment, idx) => (
            <CommentCard
              key={idx}
              className={`w-[80%] ${
                user.user_id !== comment.feed_comment_author_id
                  ? "self-end"
                  : "self-start ml-11"
              }`}
              detail={comment}
              reverse
            >
              <span
                role="button"
                onClick={() => handleReply(comment)}
                className="absolute left-0 top-3 text-ileads-lightblue text-sm"
              >
                Reply
              </span>
            </CommentCard>
          ))}
        </div>

        <div ref={loadMoreRef} />

        <textarea
          className="
            w-full
            transition-all
            sticky
            bottom-10
            left-0
            z-10
            mt-[38px]
            px-7
            py-4
            rounded-3xl
            resize-none
            outline-none
            focus:outline-none
            focus-within:outline-none
            bg-ileads-bgblue
            border-2
            shadow-2xl
            border-ileads-lightblue
          "
          placeholder="Add comment..."
          ref={commentField}
          maxLength={500}
          value={comment}
          disabled={isLoading}
          onKeyUp={handleSendComment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="flex items-end justify-end sticky bottom-5 left-0">
          <p className="font-brandon text-[11px]">{comment.length || 0}/500</p>
        </div>
        {isSending ? "Sedang mengirim..." : null}
      </div>

      {isLoading && (
        <p className="flex items-center mt-20 mb-5 justify-center text-ileads-mainblue">
          <span className="flex h-6 w-6 items-center justify-center relative mr-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-ileads-lightblue opacity-75" />
            <span className="relative inline-flex rounded-full h-4 w-4 bg-ileads-mainblue" />
          </span>
          Loading more comments...
        </p>
      )}
    </section>
  );
};

export default FeedDetail;
