import clientAPI from "./clientApi";

// list data category event
export const getCategoriesEvent = async () => {
  const { data } = await clientAPI().get("/v1/category-event");
  return data;
};

// list data event
export const getListEvent = async (params) => {
  const { data } = await clientAPI().get("/v1/event", { params });
  return data;
};

// list data type event
export const getTypeEvent = async () => {
  const { data } = await clientAPI().get("/v1/type-event");
  return data;
};

// Create Event
export const postCreateEvent = async (payload) => {
  const { data } = await clientAPI().post("/v1/event", payload);

  return data;
};

// Edit Event
export const putEditEvent = async (id, payload) => {
  const { data } = await clientAPI().put(`/v1/event/${id}`, payload);

  return data;
};

// Event Detail
export const getEventbyId = async (id) => {
  const { data } = await clientAPI().get(`/v1/event/${id}`);
  return data;
};

// Event Detail by Hashtag
export const getEventbyHashtag = async (hastag) => {
  const { data } = await clientAPI().get(`/v1/event/hashtag/${hastag}`);
  return data;
};

// Event invite
export const postEventInvite = async (id, payload) => {
  const { data } = await clientAPI().post(`/v1/event/${id}/invitation`, payload);
  return data;
};

// join event
export const putEventJoin = async (id, payload) => {
  const { data } = await clientAPI().put(`/v1/event/${id}/join`, payload);
  return data;
};

// delete event
export const deleteEvent = async (id) => {
  const { data } = await clientAPI().delete(`/v1/event/${id}`);
  return data;
};

// react event
export const reactEvent = async (id, payload) => {
  const { data } = await clientAPI().put(`/v1/event/${id}/react`, payload);
  return data;
};
