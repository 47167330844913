import ImageDefault from "Assets/Images/default-ava.png";

export const imageLink = (image) => {

  if (!image) {
    return ImageDefault;
  }

  const currentUrl = window.location.href?.split("/");
  const isDev = currentUrl.includes("webdev");

  return `https://ilead.id/storage/${isDev ? "development" : "production"}/file/image/${image}`;
};
