import styled from "styled-components";
import { Colors } from "Themes";

const ContentWrapper = styled.section`
  background-color: ${Colors.white};
  padding: 20px 20px 30px;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export {
  ContentWrapper
};
