import { LinkIcon } from "Assets/Images";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { imageLink } from "Utils/imageLink";

const CardEvent = (props) => {

  const navigation = useNavigate();

  const handleToEventFeed = (hashtag) => {
    navigation(`/feeds/event/${hashtag.replace("#", "")}`);
  };

  return (
    <div className="flex flex-col items-center justify-start">
      <div className={`flex gap-4 max-w-[90%] p-4 rounded-xl ${props.isMe && !props.isFinished && "bg-ileads-green/40"} ${props.isJoin && !props.isMe && !props.isFinished && "bg-ileads-yellow/40"}`}>
        <div className={`h-[340px] max-h-[340px] min-w-[255px] max-w-[255px] border flex justify-center items-center overflow-hidden bg-white ${props.isFinished && "grayscale"}`}>
          <img width={255} height={340} className="w-auto" alt="event-poster" src={imageLink(props.posterUrl)}/>
        </div>
        <div className="flex justify-start text-start flex-col gap-1 min-w-0 max-w-[400px]">
          <p className={`text-ileads-darkblue text-2xl font-brandon_medium ${props.isFinished && "text-[#BDBDBD]"}`}>{props.name}</p>
          <p className={`text-ileads-darkblue text-lg font-brandon_medium ${props.isFinished && "text-[#BDBDBD]"}`}>
            {
                  `${moment(props.startTime).format("DD MMMM YYYY, HH:mm")} ${props.timezone?.toUpperCase()} s/d 
                ${moment(props.endTime).format("DD MMMM YYYY, HH:mm")} ${props.timezone?.toUpperCase()}`
            }
          </p>
          {props.locationDetail && 
                <p className={`text-ileads-darkblue text-lg font-brandon_medium ${props.isFinished && "text-[#BDBDBD]"}`}>
                  {props.locationDetail}
                </p>
          }
          <span className="flex items-center gap-1">
            <img style={{ height: "10px", width: "auto" }} src={LinkIcon} alt="link-icon"/>
            <a href={props.location ? props.location : ""} target="_blank" className={`text-ileads-darkblue text-lg underline font-brandon_medium truncate ${props.isFinished && "text-[#BDBDBD]"}`} rel="noreferrer">{props.location}</a>
          </span>
          <p 
            aria-hidden="true" 
            className={`text-ileads-darkblue text-2xl font-brandon_medium border-b-4 cursor-pointer
            border-ileads-yellow w-fit max-w-[322px] truncate ${props.isFinished && "text-[#BDBDBD]"}`}
            onClick={() => handleToEventFeed(props.hashtag)} 
          >
            {props.hashtag ? props.hashtag : ""}
          </p>
          <div className="rounded-xl mt-2 overflow-hidden">
            <p className={`text-sm whitespace-pre-line ${props.isFinished && "text-[#BDBDBD]"}`}>
              {props.description?.length > 120 ?
                  `${props.description.substring(0, 120)}...` : props.description
              }
            </p>
          </div>
          <div className="flex mt-2 justify-between w-full">
            {
              !props.isMe && props.isFinished && <div>
                <button
                  type="button" 
                  disabled={true}
                  className="bg-ileads-lightgrey cursor-not-allowed w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                >
                  <span className="font-brandon_medium">Terlewat!</span>
                </button>
              </div>
            }

            {!props.isMe && !props.isFinished && <div>
              {!props.isJoin ? (
                <button
                  type="button" 
                  disabled={props.isloading}
                  className={`${props.isloading ? "bg-ileads-lightgrey cursor-not-allowed" : "bg-ileads-green"} w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap`}
                  onClick={() => props.handleActionEvent("join-event", props.id)}
                >
                  <span className="font-brandon_medium">Ikut!</span>
                </button>
              ) : (
                <button
                  type="button" 
                  disabled={props.isloading}
                  className={`${props.isloading ? "bg-ileads-lightgrey cursor-not-allowed" : "bg-ileads-danger-3"} w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap`}
                  onClick={() => props.handleActionEvent("unjoin-event", props.id)}
                >
                  <span className="font-brandon_medium">Batal Ikut!</span>
                </button>

              )}
            </div>

            }
            <button
              type="button" 
              className="flex items-center gap-2 bg-ileads-lightblue text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
              onClick={() => navigation(`/event/${props.id}`)}
            >
              <span className="font-brandon_medium">Lihat Detail</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEvent;
