import { deleteFeed } from "Api/feed";
import {
  HappyIcon,
  HeartRedIcon,
  LikeIcon,
  SadIcon,
  TrashIcon
} from "Assets/Images";
import { useUsers } from "Context/UsersStateManagement";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import "./CommentCard.css";
import { convertLinks } from "Utils/textLInk";

const CommentCard = ({
  detail,
  withReaction,
  reaction,
  reverse,
  canDelete,
  className,
  children,
  baseUrl
}) => {
  const [messageData, setMessageData] = useState("");
  const alertComponent = useAlert();
  const navigate = useNavigate();
  const { setFeedDeleted } = useUsers();

  const reactions = detail?.feed_reactions?.map(
    (reaction) => reaction.feed_react_reaction
  );

  const handleDeleteFeed = async () => {
    const confirmDelete = confirm("Yakin ingin menghapus feed ini?");
    if (!confirmDelete) return;
    try {
      setFeedDeleted(true);
      await deleteFeed(detail.feed_id);
      alertComponent.success("Feed anda berhasil dihapus!");
      navigate(baseUrl ? baseUrl : "/feeds");
    } catch (error) {
      alertComponent.show(error.response.data.message);
    } finally {
      setFeedDeleted(false);
    }
  };

  useEffect(() => {
    const comment = detail?.feed_comment_comment || detail?.feed_description;
    setMessageData(
      `${comment?.replaceAll(
        /(@)([a-z]+)/gi,
        "<span class='text-ileads-lightblue'>$1$2</span>"
      )}`
    );
  }, [detail]);

  return (
    <div className={`${className}`}>
      <div
        className={`
          bg-ileads-bgblue
          mt-3
          rounded-[14px]
          py-4
          px-5
          relative
          after:absolute
          after:-bottom-4
          after:rotate-45
          after:w-9
          after:h-9
          after:bg-ileads-bgblue
          after:rounded-tl-3xl
          ${reverse ? "after:right-6" : "after:left-6"}
        `}
      >
        <p
          className="content-data break-words"
          dangerouslySetInnerHTML={{ __html: convertLinks(messageData) }}
        />
        {canDelete && (
          <button onClick={handleDeleteFeed}>
            <img
              className="
                absolute
                z-10
                -top-5
                -left-3
                cursor-pointer
                transition-all
                hover:scale-110
                hover:-translate-y-2
              "
              src={TrashIcon}
              alt=""
            />
          </button>
        )}
        {withReaction && (!!reactions?.length || reaction) && (
          <div
            className="
              absolute
              right-2
              -top-8
              min-w-max
              flex
              gap-[10px]
              py-2
              px-4
              bg-white
              shadow-lg
              rounded-full
            "
          >
            {(reactions.includes("love") || reaction === "love") && (
              <img className="w-8" src={HeartRedIcon} alt="" />
            )}
            {(reactions.includes("like") || reaction === "like") && (
              <img className="w-8" src={LikeIcon} alt="" />
            )}
            {(reactions.includes("happy") || reaction === "happy") && (
              <img className="w-8" src={HappyIcon} alt="" />
            )}
            {(reactions.includes("sad") || reaction === "sad") && (
              <img className="w-8" src={SadIcon} alt="" />
            )}
          </div>
        )}
      </div>

      <div
        className={`flex relative transition-all items-center justify-between pt-[14px] ${
          reverse ? "flex-row-reverse pr-16" : "pl-16"
        }`}
      >
        <div
          className={`flex items-center ${reverse ? "flex-row-reverse" : ""}`}
        >
          <figure>
            <img
              className="h-[50px] w-[50px] object-cover rounded-xl"
              src={detail.feed_author_photo || detail.feed_comment_author_photo}
              alt=""
            />
          </figure>
          <div className={`${reverse ? "mr-[14px] text-right" : "ml-[14px]"}`}>
            <strong className="block font-brandon_bold">
              {detail.feed_author_nickname ||
                detail.feed_comment_author_nickname}
            </strong>
            <span className="text-[11px]">
              {detail.feed_author_team_1 || ""}
            </span>
          </div>
        </div>

        {children}
      </div>
    </div>
  );
};

export default CommentCard;
