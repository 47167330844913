import clientAPI from "./clientApi";

export const getAllFeeds = async (params) => {
  const { data } = await clientAPI().get("/v2/feed", { params });

  return data;
};

export const getFeedDetail = async (id, params) => {
  const {
    data: { data }
  } = await clientAPI().get(`/v2/feed/${id}`, { params });

  return data;
};

export const getFeedComments = async (id, params) => {
  const { data } = await clientAPI().get(`/v2/feed/${id}/comment`, { params });

  return data;
};

export const getFeedCategories = async () => {
  const {
    data: { data }
  } = await clientAPI().get("/v1/feed-type");

  return data;
};

export const createFeed = async (payload) => {
  const {
    data: { data }
  } = await clientAPI().post("/v1/feed", payload);

  return data;
};

export const createComment = async (payload) => {
  await clientAPI().post("/v1/comment", payload);
};

export const addFeedReact = async (payload) => {
  await clientAPI().post("/v1/feed-react", payload);
};

export const deleteFeed = async (id) => {
  const { data } = await clientAPI().delete(`/v1/feed/${id}`);

  return data;
};
