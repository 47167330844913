/* eslint-disable sort-keys */
import { getCategoriesEvent, getEventbyId, getTypeEvent, putEditEvent } from "Api/event";
import { uploadAttachment } from "Api/upload";
import PosterEvent from "Assets/Icons/event-poster.svg";
import SuccessIcon from "Assets/Icons/success-icon.svg";
import { AttachImage, CalendarPen, CloseRound, ConfuseMan, ImageUploadIcon, LinkIcon } from "Assets/Images";
import { CircularLoader, DropdownInput, Image, Modal } from "Components";
import { Constants } from "Constants";
import { useYupValidationResolver } from "Hooks";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AddEventSchema } from "Schemas/CreateEventSchema";
import { Colors } from "Themes";
import { DropdownOptions } from "Utils";
import { transformTitle } from "Utils/Hastag";
import { CheckboxInput, ContentWrapper, DropdownContainer, PreviewWrapper } from "./index.style";
import "./index.style.css";
const { TimeZoneIndo, EventType } = Constants;

const EventCreate = () => {

  const navigate = useNavigate();
  const fileRef = useRef(null);
  const alertComponent = useAlert();
  const { id } = useParams();

  // Get a specific query parameter
  const [searchParams, setSearchParams] = useSearchParams();
  const previewQuery = searchParams.get("preview");

  // Foto upload
  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState("");
  const [localURL, setLocalURL] = useState("");
  const [newPhoto, setNewPhoto] = useState(false);

  const [dataCreate, setDataCreate] = useState({});
  const [dataCategories, setDataCategories] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);

  const [confirmPopup, setConfirmPopup] = useState({
    isOpen: false,
    action: ""
  });

  const [isLoading, setIsLoading] = useState(true);

  // form input
  const resolver = useYupValidationResolver(AddEventSchema);
  const { control, formState, handleSubmit, watch, resetField, setValue, register, setError, clearErrors } = useForm({
    resolver,
    defaultValues: {
      categoriesEvent: [],
      description: "",
      endTime: new Date(),
      hashtag: "",
      implementation: "",
      linkMeeting: "",
      locationMap: "",
      locationName: "",
      name: "",
      posterUrl: "",
      startTime: new Date(),
      timezone: "wib",
      typeEvent: ""
    }
  });
  const { errors } = formState;
  
  const valueType = watch("implementation");

  //* Function create
  const openImageFile = () => {
    fileRef.current.click();
  };

  const handleUploadFile = async (event) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 2MB
    
    const file = event.target.files[0];
    
    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      
      clearErrors("posterUrl");
      setFileUpload(file);
      setFileName(file.name);
      setNewPhoto(true);

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setLocalURL(reader.result);
      });
      
      reader.readAsDataURL(file);
  

    } else {
      setFileUpload(null);
      if (!allowedTypes.includes(file.type)) {
        setError("posterUrl");
        alertComponent.error("Please select a valid file type (JPEG, PNG, JPG)");
      } else if (file.size > maxSize) {
        setError("posterUrl");
        alertComponent.error("File size exceeds 2MB limit");
      }
    }

  };

  const handleClearFile = () => {
    fileRef.current.value = "";
    setFileUpload(null);
    setFileName("");
    setLocalURL("");
  };

  const handleNavigateBack = () => {
    navigate("/event");
  };

  const onSubmit = (value) => {

    if (value.categoriesEvent.length < 1) {
      setError("categoriesEvent");
    }

    if (localURL === "") {
      setError("posterUrl");
    }

    const dataSubmit = {
      categoryIds: value.categoriesEvent,
      description: value.description,
      endTime: moment(value.endTime).utc().format(),
      hashtag: value.hashtag ? value.hashtag : `#${transformTitle(value.name)}`,
      implementation: value.implementation,
      location: value.implementation === "online" ?  value.linkMeeting : value.locationMap,
      locationDetail: value.locationName,
      name: value.name,
      posterUrl: value.posterUrl,
      startTime: moment(value.startTime).utc().format(),
      timezone: value.timezone,
      typeId: value.typeEvent?.value
    };

    setDataCreate(dataSubmit);

    // setQueryPreview
    if (localURL !== "" && value.categoriesEvent.length !== 0) {
      setSearchParams({ preview: "true" });
    }

  };

  const handleUpdateEvent = async () => {

    if (newPhoto) {
      
      const formData = new FormData();
      formData.append("files", fileUpload);
      const { urls: documentsUrl } = await uploadAttachment(formData);
  
      const newDataWithPhoto = {
        ...dataCreate, posterUrl: documentsUrl
      };

      await postEventUpdate(newDataWithPhoto);

    } else {

      await postEventUpdate(dataCreate);

    }


  };

  const handleSubmitModal = () => {
    if (confirmPopup.action === "edit") {
      handleUpdateEvent();
    } else if(confirmPopup.action === "cancel-edit") {
      navigate(`/event/${id}`);
    }
  };

  const handleRemoveQuery = () => {
    // Remove a specific query parameter
    setSearchParams((params) => {
      params.delete("preview");
      return params;
    });
  };

  //* Function call API
  const getCategoryEvent = async () => {
    try {
      const { data } = await getCategoriesEvent();
      const newData = data.map(data => ({ ...data, selected: false }));
      setDataCategories(newData);

    } catch (error) {
      alertComponent.show(error.response.data.message || "Error Get Category Event");      
    }
  };

  const getTypeListEvent = async () => {
    try {
      const { data } = await getTypeEvent();
      setDataTypes(data);

    } catch (error) {
      alertComponent.show(error.response.data.message || "Error Get Type Event");      
    }
  };

  const postEventUpdate = async (payload) => {
    try {
      const { data } = await putEditEvent(id, payload);
      alertComponent.success("Sukses update Acara Ceria");      
      navigate(`/event/${data.id}`);
    } catch (error) {
      alertComponent.error(error.response.data.message || "Error Create Event");      
    }
  };

  const selectedCategoryFilter = (selectedData, listData) => {
    const selected = listData
      .filter(category => selectedData.includes(category.id))
      .map(category => category.name)
      .join(", ");

    return selected;
  };

  const getDetailEvent = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await getEventbyId(id);

      setValue("name", data.name);
      setValue("startTime", data.startTime);
      setValue("endTime", data.endTime);
      setValue("timezone", data.timezone);
      setValue("implementation", data.implementation);

      if (data.implementation === "online") {
        setValue("linkMeeting", data.location);
      } else {
        setValue("locationMap", data.location);
        setValue("locationName", data.locationDetail);
      }

      const currentUrl = window.location.href?.split("/");
      const isDev = currentUrl.includes("webdev");

      setLocalURL(`https://ilead.id/storage/${isDev ? "development" : "production"}/file/image/${data.posterUrl}`);
      setFileName(data.posterUrl);
      setValue("posterUrl", data.posterUrl);

      setValue("hashtag", data.hashtag);
      setValue("description", data.description);
      setValue("categoriesEvent", data.categoryIds);
      setValue("typeEvent", {
        label: data.type.name,
        value: data.typeId
      });

      setIsLoading(false);
        
    } catch (error) {
      setIsLoading(false);
      alertComponent.error(error.response.data.message || "Error get event detail");
    }
  };

  //* Component Edit
  const renderHeader = () => (
    <div className="flex flex-row justify-between mb-4">
      <div className="mr-6">
        <p className="font-brandon_bold text-ileads-mainblue text-lg">{`${previewQuery ? "Preview": "Edit"} Acara Ceria`}</p>
        <div className="bg-ileads-yellow h-2 w-42" />
      </div>
      {
        !previewQuery ? (
          <button
            onClick={handleNavigateBack}
            className="bg-ileads-red text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap"
          >
            <span className="font-brandon_medium">Batal</span>
          </button>
        ): null
      }
      
    </div>
  );

  const renderName = () => (
    <div className="mb-4">
      <p className="text-ileads-mainblue font-brandon_bold text-lg">
        <span className="text-ileads-lightblue font-brandon_bold text-lg">Nama</span> Acara Ceria
      </p>
      <Controller
        name="name"
        control={control}
        rules={{ maxLength: 120 }}
        render={({ field }) => (
          <div className="flex flex-col mt-2">
            <div
              className={`input-${
                        errors["name"] ? "error" : "primary"
                      } bg-ileads-white flex items-center space-x-1.5 px-4 w-full`}
            >
              <input
                value={field.value}
                placeholder="Tulis nama acara di sini."
                onChange={field.onChange}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="name"
                maxLength={120}
              />
            </div>
            <div className="flex items-end justify-between mt-2">
              <p className="font-brandon text-ileads-danger-3 text-base">{errors["name"]?.message}</p>
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/120</p>
            </div>
          </div>
        )}
      />
    </div>
  );

  const renderInputOnline = () => (
    <>
      <p className="text-ileads-mainblue font-brandon_bold text-lg">
        <span className="text-ileads-lightblue font-brandon_bold text-lg" >
          Link Pelaksanaan
        </span> Acara Ceria (MS Teams, Zoom, dll.)
      </p>
      <Controller
        name="linkMeeting"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col mt-2">
            <div
              className={`input-${
                        errors["linkMeeting"] ? "error" : "primary"
                      } bg-ileads-white flex items-center space-x-1.5 px-4 w-full`}
            >
              <input
                value={field.value}
                placeholder="Tulis link pelaksanaan acara di sini."
                onChange={field.onChange}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="linkMeeting"
              />
            </div>
          </div>
        )}
      />
    </>
  );

  const renderInputOffline = () => (
    <>
      <div className="w-full">
        <p className="text-ileads-mainblue font-brandon_bold text-lg">
          <span className="text-ileads-lightblue font-brandon_bold text-lg">
            Nama Lokasi
          </span> Acara Ceria
        </p>
        <Controller
          name="locationName"
          control={control}
          render={({ field }) => (
            <div className="flex flex-row mt-2 mb-4">
              <div
                className={`input-${
                errors["locationName"] ? "error" : "primary"
              } bg-ileads-white flex items-center space-x-1.5 px-4 w-full`}
              >
                <input
                  value={field.value}
                  placeholder="Tulis lokasi acara di sini."
                  onChange={field.onChange}
                  className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                  name="locationName"
                />
              </div>
            </div>
          )}
        />
      </div>

      <div className="w-full">
        <p className="text-ileads-lightblue  font-brandon_bold text-lg">
          Link Google Map
        </p>
        <Controller
          name="locationMap"
          control={control}
          render={({ field }) => (
            <div className="flex flex-row mt-2">
              <div
                className={`input-${
                errors["locationMap"] ? "error" : "primary"
              } bg-ileads-white flex items-center space-x-1.5 px-4 w-full`}
              >
                <input
                  value={field.value}
                  placeholder="Tulis link google map di sini."
                  onChange={field.onChange}
                  className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                  name="locationMap"
                />
              </div>
            </div>
          )}
        />
      </div>
    </>
  );

  const renderTimeSection = () => (
    <div className="mb-4">
      <p className="text-ileads-mainblue font-brandon_bold text-lg">
        <span className="text-ileads-lightblue font-brandon_bold text-lg">
          Waktu Pelaksanaan 
        </span>
        {" "}Acara Ceria
      </p>
      <div className="flex flex-col justify-start gap-2 mt-2 mb-6">
        <div className="flex gap-2 items-center">
          <div className="my-auto">
            <Image src={CalendarPen} width={24} height={24} />
          </div>
          {/* date and time start */}
          <Controller
            name="startTime"
            control={control}
            render={({ field }) => (
              <div className={`input-${
                      errors["startTime"] ? "error" : "primary"
                    } bg-ileads-white flex items-center space-x-1.5 px-4 w-fit`}>
                <div className="text-center text-ileads-mainblue w-full cursor-pointer mx-6 h-min-[49px]">
                  <DatePicker
                    selected={field.value ? new Date(field.value) : new Date()}
                    onChange={field.onChange}
                    showTimeSelect
                    timeIntervals={15}
                    timeFormat="HH:mm"
                    customInput={<span className="font-brandon my-3">{moment(field.value).format("DD MMMM YYYY, HH:mm")}</span>}
                  />
                </div>
              </div>
            )}
          />

          <p className="text-center">{"-"}</p>

          {/* date and time end */}
          <Controller
            name="endTime"
            control={control}
            render={({ field }) => (
              <div className={`input-${
                      errors["endTime"] ? "error" : "primary"
                    } bg-ileads-white flex items-center space-x-1.5 px-4 w-fit`}>
                <div className="text-center text-ileads-mainblue w-full cursor-pointer mx-6 h-min-[49px]">
                  <DatePicker
                    selected={field.value ? new Date(field.value) : new Date()}
                    onChange={field.onChange}
                    showTimeSelect
                    timeIntervals={15}
                    timeFormat="HH:mm"
                    customInput={<span className="font-brandon my-3">{moment(field.value).format("DD MMMM YYYY, HH:mm")}</span>}
                  />
                </div>
              </div>
            )}
          />

          {/* zone */}
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <DropdownContainer>
                <DropdownInput
                  type={"secondary"}
                  options={DropdownOptions.convertToOptions(TimeZoneIndo, "label", "value")}
                  value={field.value.toUpperCase()}
                  onSelect={(objValue) => {
                    field.onChange(objValue.value);
                  }}
                  name={"timezone"}
                  placeholder={"Pilih Timezone"}
                  error={errors["timezone"]}
                  wrapperClassName="w-36"
                  fontSize={16}
                />
              </DropdownContainer>
            )}
          />
        </div>
      </div>
    </div>
  );

  const renderImplementation = () => (
    <>
      {/* implementation Online / Offline */}
      <div className="mb-4">
        <p className="text-ileads-mainblue font-brandon_bold text-lg">
          <span className="text-ileads-lightblue font-brandon_bold text-lg">
            Pelaksanaan
          </span> Acara Ceria
        </p>
        <Controller
          name="implementation"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <div className="mb-4">
                <DropdownInput
                  type={"secondary"}
                  options={DropdownOptions.convertToOptions(EventType, "label", "value")}
                  value={field.value}
                  onSelect={(objValue) => {
                    field.onChange(objValue.value);
                  }}
                  name={"implementation"}
                  placeholder={"Pilih type event"}
                  error={errors["implementation"]}
                  wrapperClassName="w-full h-fit"
                  fontSize={16}
                />
              </div>
            </div>
          )}
        />
      </div>

      {/* input Online (Meeting Link) or Offline (name & location) */}
      <div className="mb-4">
        {valueType === "online" && renderInputOnline()}
        {valueType === "offline" && renderInputOffline()}
      </div>
    </>
  );

  const renderUploadImage = () => (
    <div className="mb-4 flex gap-2">
      <div className="p-2 flex justify-center items-center bg-white w-32 h-[120px] max-h-32">
        <img className="object-contain max-h-32" width="auto" height={120} alt="event-poster" src={localURL ? localURL : PosterEvent}/>
      </div>
      <div className="self-end">
        <div className="flex gap-2 items-center">
          <button
            onClick={openImageFile}
            type="button"
            className="rounded-full w-[194px] py-2 transition-opacity hover:opacity-50 bg-ileads-yellow"
          >
                Upload Poster
          </button>
          {fileName && <div className="flex gap-1 item-center">
            <img src={AttachImage} alt="" />
            <span
              className="text-sm font-brandon max-w-[127px] text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {fileName}
            </span>
            <button type="button" onClick={handleClearFile}>
              <img src={CloseRound} width={20} height={20} alt="ic-close" className="cursor-pointer"  />
            </button>
          </div>}
        </div>
        <input
          type="file"
          ref={fileRef}
          accept="image/jpg, image/jpeg, image/png"
          className="hidden"
          onChange={handleUploadFile}
        />
        <p className={`${errors.posterUrl ? "text-ileads-danger-2 text-xs" : "text-xs"} mt-2`}>Poster maksimal berukuran 2 MB dan dapat berupa .png dan .jpg</p>
      </div>
    </div>
  );

  const renderHashtage = () => (
    <div className="mb-4">
      <p className="text-ileads-mainblue font-brandon_bold text-lg">
        <span className="text-ileads-lightblue font-brandon_bold text-lg">
          Hashtag
        </span> Untuk Feed
      </p>
      <Controller
        name="hashtag"
        control={control}
        rules={{ maxLength: 100 }}
        render={({ field }) => (
          <div className="flex flex-col mt-2">
            <div
              className={`input-${
                        errors["hashtag"] ? "error" : "primary"
                      } bg-ileads-white flex items-center space-x-1.5 px-4 w-full`}
            >
              <input
                {...field}
                maxLength={100}
                placeholder="#hashtag"
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
              />
            </div>
            <div className="flex items-end justify-between mt-2">
              <p className="font-brandon text-ileads-danger-3 text-base">{errors["hashtag"]?.message}</p>
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/100</p>
            </div>
          </div>
        )}
      />
    </div>
  );

  const renderDescription = () => (
    <div className="mb-4">
      <p className="text-ileads-mainblue font-brandon_bold text-lg">
        <span className="text-ileads-lightblue font-brandon_bold text-lg">
          Deskripsi
        </span> Acara Ceria
      </p>
      <Controller
        name="description"
        control={control}
        rules={{ maxLength: 1500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${
          errors["description"] ? "error" : "primary"
        } bg-ileads-white flex items-center space-x-1.5 px-2 mt-2 mb-1`}
              style={{
                height: "auto"
              }}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={field.onChange}
                className="input-password-custom text-left text-ileads-mainblue w-full h-auto text-ellipsis font-brandon"
                name="description"
                maxLength={1500}
              />
            </div>
            <div className="flex items-end justify-between mb-3">
              <p className="font-brandon text-ileads-danger-3 text-base">{errors["description"]?.message}</p>
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/1500</p>
            </div>
          </>
        )}
      />
    </div>
  );

  const renderCategories = () => (
    <div className="mb-4">
      <p className="text-ileads-mainblue font-brandon_bold text-lg">
        <span className="text-ileads-lightblue font-brandon_bold text-lg">
          Kategori
        </span> Acara Ceria
      </p>

      <div className="flex flex-col mt-2 gap-1">

        {dataCategories.map(category => (
          <label className="flex items-center check-custom" key={category.id} htmlFor={`checkbox${category.id}`}>
            <CheckboxInput
              id="check-event"
              value={category.id}
              {...register("categoriesEvent")}
              style={{
                borderRadius: "0px !important"
              }}
            />
            <span className="ml-2 text-base">{category.name}</span>
          </label>
        ))}
      </div>
      {errors.categoriesEvent && <p className="mt-3 font-brandon text-ileads-danger-3 text-base">Pilih minimal 1 kategori</p>}
    </div>
  );

  const renderTypeId = () => (
    <div className="mb-4">
      <p className="text-ileads-mainblue font-brandon_bold text-lg">
        <span className="text-ileads-lightblue font-brandon_bold text-lg">
          Tipe
        </span> Acara Ceria
      </p>
      <Controller
        name="typeEvent"
        control={control}
        render={({ field }) => (
          <div className="w-full">
            <div className="mb-4">
              <DropdownInput
                type={"secondary"}
                options={DropdownOptions.convertToOptions(dataTypes, "name", "id")}
                value={field.value}
                onSelect={field.onChange}
                name={"typeEvent"}
                placeholder={"Pilih tipe acara ceria"}
                error={errors["typeEvent"]}
                wrapperClassName="w-full"
                fontSize={16}
              />
            </div>
          </div>
        )}
      />
    </div>
  );

  const renderLoader = () => (
    <div className="flex items-center justify-center my-12">
      <CircularLoader size={30} color={Colors.yellow} unfilledColor={Colors.darkBlue} />
    </div>
  );

  useEffect(() => {

    getDetailEvent(id);

    // fetch category event list
    getCategoryEvent();

    // fetch type event list
    getTypeListEvent();
  }, []);
  

  useEffect(() => {
    if (valueType === "online") {
      resetField("locationName");
      resetField("locationMap");
    } else {
      resetField("linkMeeting");
    }
  }, [valueType]);

  return (
    <React.Fragment>
      {renderHeader()}
      {
        previewQuery ? (
          <PreviewWrapper>
            <div className="flex justify-end">
              <button
                type="button" 
                className="bg-ileads-lightblue text-white text-center py-3 rounded-btn px-6 whitespace-nowrap"
                onClick={handleRemoveQuery}
              >
                <span className="font-brandon_medium">Ganti Detail</span>
              </button>
            </div>
            <div className="flex mt-6 justify-center">
              <div className="flex gap-4 max-w-[80%]">
                <div className="h-[340px] min-w-[255px] max-w-[255px] border flex justify-center items-center">
                  <img width={255} height={340} className="w-auto" alt="event-poster" src={localURL ? localURL : ImageUploadIcon}/>
                </div>
                <div className="flex flex-col gap-1 min-w-0">
                  <p className="text-ileads-darkblue text-2xl font-brandon_medium truncate">{dataCreate.name}</p>
                  <p className="text-ileads-darkblue text-lg font-brandon_medium">
                    {
                        `${moment(dataCreate.startTime).format("DD MMMM YYYY, HH:mm")} ${dataCreate.timezone?.toUpperCase()} s/d 
                      ${moment(dataCreate.endTime).format("DD MMMM YYYY, HH:mm")} ${dataCreate.timezone?.toUpperCase()}`
                    }
                  </p>
                  {dataCreate.locationDetail && 
                    <p className="text-ileads-darkblue text-lg font-brandon_medium">
                      {dataCreate.locationDetail}
                    </p>
                  }
                  <span className="flex items-center gap-1">
                    <img className="h-[10px]" height="10px" width="auto" src={LinkIcon} alt="link-icon"/>
                    <a href={dataCreate.location} target="_blank" className="text-ileads-darkblue text-lg underline font-brandon_medium truncate" rel="noreferrer">{dataCreate.location}</a>
                  </span>
                  <p className="text-ileads-darkblue text-2xl font-brandon_medium border-b-4 border-ileads-yellow w-fit max-w-[322px] truncate">
                    {dataCreate.hashtag}
                  </p>
                  <div className="p-3 rounded-xl mt-2 bg-[#DFEFFC] border-ileads-mainblue border-2">
                    <p className="text-sm mb-3 whitespace-pre-line">{dataCreate.description}</p>
                    <p className="text-sm">
                      <span className="text-ileads-darkblue text-sm font-brandon_medium">
                        Kategori:
                      </span>
                      {" "} {selectedCategoryFilter(dataCreate.categoryIds, dataCategories)}
                    </p>
                    <p className="text-sm">
                      <span className="text-ileads-darkblue text-sm font-brandon_medium">
                        Tipe Kegiatan:
                      </span>
                      {" "}{dataTypes.filter(data => data.id === dataCreate.typeId)[0]?.name || "tipe kegiatan"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-3 mt-8">
              <button
                type="button" 
                className="bg-ileads-danger-3 text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-[200px]"
                onClick={() => setConfirmPopup({
                  isOpen: true,
                  action: "cancel-edit"
                })}
              >
                Batal
              </button>

              <button
                type="button" 
                className="bg-ileads-green text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-[200px]"
                onClick={() => setConfirmPopup({
                  isOpen: true,
                  action: "edit"
                })}
              >
                Update Acara Ceria
              </button>
            </div>

            <Modal
              show={confirmPopup.isOpen}
              onClose={() => setConfirmPopup(data => ({ ...data, isOpen: false }))}
              title={confirmPopup.action !== "" ? Constants.ModalConfirm.filter(data => data.status === confirmPopup.action)[0]?.title : "title"}
              desc={confirmPopup.action !== "" ? Constants.ModalConfirm.filter(data => data.status === confirmPopup.action)[0]?.description : "dec"}
              icon={<img src={confirmPopup.action !== "cancel-success" ? ConfuseMan : SuccessIcon } width={confirmPopup.action !== "cancel-success" ? "300px" : "142px"} height="auto" alt="popup" />}
              buttons={
                <div className="flex gap-3">
                  {
                    confirmPopup.action !== "cancel-success" && <button
                      type="button" 
                      className="bg-ileads-danger-3 text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-32"
                      onClick={() => setConfirmPopup(data => ({ ...data, isOpen: false }))}
                    >
                    Tidak
                    </button>
                  }
                  
                  <button
                    type="button" 
                    className={`bg-ileads-green text-white text-center py-3 rounded-btn px-6 whitespace-nowrap ${confirmPopup.action !== "cancel-success" ? "w-32" : "w-fit"}`}
                    onClick={handleSubmitModal}
                  >
                    {confirmPopup.action !== "cancel-success" ? "Iya": "Kembali ke Acara Ceria"}
                  </button>
                </div>
              }
            />
          </PreviewWrapper>
        ) : (
          <ContentWrapper onSubmit={handleSubmit(onSubmit)}>
            {isLoading ? renderLoader() : (
              <div>
                {Object.keys(errors).length ? (
                  <span className="text-ileads-danger-3 my-4 text-xl block text-center">
                    Ups! Sepertinya ada kolom yang belum diisi! Silahkan dicek kembali dan isi semua kolom yang tersedia!
                  </span>
                ) : null}

                {/* name event */}
                {renderName()}
                      
                {/* Time (date, time, & timezone) */}
                {renderTimeSection()}

                {/* implementation Online / Offline */}
                {renderImplementation()}

                {/* Image Upload */}
                {renderUploadImage()}

                {/* Hastag */}
                {renderHashtage()}

                {/* Description */}
                {renderDescription()}

                {/* Category ID */}
                {renderCategories()}

                {/* Type ID */}
                {renderTypeId()}

                <div className="flex justify-center">
                  <button
                    type="submit" 
                    className="bg-ileads-lightblue text-white text-center py-3 rounded-btn px-6 whitespace-nowrap"
                  >
                    <span className="font-brandon_medium">Preview Event</span>
                  </button>
                </div>
              </div>
            )}

          </ContentWrapper>
        )
      }
      
    </React.Fragment>
  );
};

export default EventCreate;
