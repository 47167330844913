import styled from "styled-components";

import { Colors } from "../../Themes";

const ArrowContainer = styled.div`
  margin-right: 24px;
  margin-top: auto;
  margin-bottom: auto;
`;

const Label = styled.p`
  font-size: 20px;
  font-family: 'Brandon-Medium';
  color: ${Colors.darkBlue};
  margin-bottom: 9px;
`;

const MainValue = styled.p`
  font-family: 'Brandon-Regular';
  font-size: ${({ fontSize }) => fontSize || 20 }px;
  color: ${({ placeholder }) => placeholder.length > 0 ? Colors.grey : Colors.mainBlue };
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 49px;
  border-radius: 30px;
  background-color: ${({ type }) => type === "primary" ? Colors.bgBlue : Colors.white};
  border: 2px ${({ type }) => type !== "primary" && `solid ${Colors.lightBlue}`};
  border: ${({ error }) => error && `2px solid ${Colors.red}`};
`;

const ShowOptionsWrapper = styled(Wrapper)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
`;

const OptionsWrapper = styled.div`
  padding-left: 24px;
  background-color: ${Colors.white};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: scroll;
  max-height: 152px;
  height: auto;
  z-index: 1000;
  border: 2px ${({ type }) => type !== "primary" ? `solid ${Colors.lightBlue}` : `solid ${Colors.bgBlue}`};
  border: ${({ error }) => error && `2px solid ${Colors.red}`};
  border-top: 0px;
`;

const Option = styled.p`
  font-family: 'Brandon-Regular';
  font-size: ${({ fontSize }) => fontSize || 20 }px;
  color: ${Colors.mainBlue};
  padding-top: 7px;
  padding-bottom: 7px;
`;

const SearchIconWrapper = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  margin-bottom: 7px;
`;

const SearchInput = styled.input`
  border-bottom: 1px ${({ type }) => type !== "primary" ? `solid ${Colors.lightBlue}` : `solid ${Colors.bgBlue}`};
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  width: 85%;
  padding: 7px;
  font-family: 'Brandon-Regular';
  font-size: 18px;
  color: ${Colors.mainBlue};
  &:focus {
    outline: none;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-top: 20px;
`;

const ErrorMessage = styled.p `
  font-size: 18px;
  font-family: 'Brandon-Medium';
  color: ${Colors.red};
  margin-bottom: 9px;
`;

export {
  ArrowContainer,
  ErrorMessage,
  Label,
  LoaderWrapper,
  MainValue,
  Option,
  OptionsWrapper,
  ShowOptionsWrapper,
  SearchIconWrapper,
  SearchInput,
  SearchInputWrapper,
  Wrapper
};
