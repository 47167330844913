import { LinkIcon } from "Assets/Images";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { imageLink } from "Utils/imageLink";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./index.css";

//* type event
const CardEvent = ({ data, handleActionEvent }) => {
  const navigation = useNavigate();

  const handleToEventFeed = (hashtag) => {
    navigation(`/feeds/event/${hashtag.replace("#", "")}`);
  };
  
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="flex gap-4 max-w-[90%]">
          <div className="h-[340px] min-w-[255px] max-w-[255px] border flex justify-center items-center">
            <img width={255} height={340} className="w-auto" alt="event-poster" src={imageLink(data.posterUrl)}/>
          </div>
          <div className="flex justify-start text-start flex-col gap-1 min-w-0 max-w-lg">
            <p className="text-ileads-darkblue text-2xl font-brandon_medium truncate">{data.name}</p>
            <p className="text-ileads-darkblue text-lg font-brandon_medium">
              {
                  `${moment(data.startTime).format("DD MMMM YYYY, HH:mm")} ${data.timezone?.toUpperCase()} s/d 
                ${moment(data.endTime).format("DD MMMM YYYY, HH:mm")} ${data.timezone?.toUpperCase()}`
              }
            </p>
            {data.locationDetail && 
                <p className="text-ileads-darkblue text-lg font-brandon_medium">
                  {data.locationDetail}
                </p>
            }
            <span className="flex items-center gap-1">
              <img style={{ height: "10px", width: "auto" }} src={LinkIcon} alt="link-icon"/>
              <a href={data.location} target="_blank" className="text-ileads-darkblue text-lg underline font-brandon_medium truncate" rel="noreferrer">{data.location}</a>
            </span>
            <p 
              aria-hidden="true" 
              className="text-ileads-darkblue cursor-pointer text-2xl font-brandon_medium border-b-4 border-ileads-yellow w-fit max-w-[322px] truncate" 
              onClick={() => handleToEventFeed(data.hashtag)} 
            >
              {data.hashtag}
            </p>
            <div className="p-3 rounded-xl mt-2 bg-[#DFEFFC] border-ileads-mainblue border-2 overflow-hidden">
              <p className="text-sm whitespace-pre-line">
                {data.description.length > 120 ?
                  `${data.description.substring(0, 120)}...` : data.description
                }
              </p>
            </div>
            <div className="flex mt-2 justify-between w-full">
              {!data.isMe && <div>
                {!data.isJoin ? (
                <button
                  type="button" 
                  className="bg-ileads-green w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                  onClick={() => handleActionEvent("join-event", data.id)}
                >
                  <span className="font-brandon_medium">Ikut!</span>
                </button>
              ) : (
                <button
                  type="button" 
                  className="bg-ileads-danger-3 w-fit text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                  onClick={() => handleActionEvent("unjoin-event", data.id)}
                >
                  <span className="font-brandon_medium">Batal Ikut!</span>
                </button>

              )}
              </div>

              }
              <button
                type="button" 
                className="flex items-center gap-2 bg-ileads-lightblue text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                onClick={() => navigation(`/event/${data.id}`)}
              >
                <span className="font-brandon_medium">Lihat Detail</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//* List of child component
const ComponentChild = {
  event: CardEvent
  //* You can add new type here
};


const Slider = (props) => {

  const { type, data } = props;

  const Component =  ComponentChild[type];

  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {
          Array.isArray(data) && data.map(dataChild => (

            <SwiperSlide key={dataChild.id}>
              <Component
                data={dataChild}
                
                // for card event
                handleActionEvent={props.handleActionEvent}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  );
};

export default Slider;


