import SearchIcon from "Assets/Icons/search-icon.svg";
import React from "react";
import Select, { components } from "react-select";

const MultipleSelect = (props) => {

  const DropdownIndicator = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={SearchIcon} width={24} height={24} alt="icon-search"/>
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      components={{ DropdownIndicator }}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      options={props.options}
      isLoading={props.isLoading}
      placeholder={props.placeholder}
      isMulti={props.isMulti}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          ":hover": {
            border: "2px solid #1F295A"
          },
          border: "2px solid #1F295A",
          borderRadius: "12px",
          marginTop: "8px",
          outline: "none",
          width: "520px"
        })
      }}
      {...props}
    />
  );
};

export default MultipleSelect;
