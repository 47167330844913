import SuccessIcon from "Assets/Icons/success-icon.svg";
import React from "react";
const { ConfuseMan, HappyFullIcon } = require("Assets/Images");

export const ImagePopUp = (action) => {
  switch (action) {
  case "join-event":
    return <img src={ConfuseMan} width={"250px"} height="auto" alt="popup" />;
  case "join-success":
    return (
      <img src={HappyFullIcon} width={"120px"} height="auto" alt="popup" />
    );
  case "unjoin-success":
    return (
      <img src={SuccessIcon} width={"120px"} height="auto" alt="popup" />
    );
  default:
    return <img src={ConfuseMan} width={"250px"} height="auto" alt="popup" />;
  }
};
