import * as yup from "yup";

export const AddEventSchema = yup.object({
  categoryEvent: yup.array().of(yup.string()),
  description: yup.string().required("Tidak boleh kosong").max(1500),
  endTime: yup.string().required("Tidak boleh kosong"),
  hashtag: yup.string().max(100).required("Tidak boleh kosong"),
  implementation: yup.string().required("Tidak boleh kosong"),
  linkMeeting: yup.string(),
  locationMap: yup.string(),
  locationName: yup.string(),
  name: yup.string().required("Tidak boleh kosong").max(120),
  posterUrl: yup.string(),
  startTime: yup.string().required("Tidak boleh kosong"),
  timezone: yup.string().required("Tidak boleh kosong"),
  typeEvent: yup.object().nullable().required("Tidak boleh kosong")
});
