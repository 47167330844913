import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const RichEditor = (props) => {

  const [charCount, setCharCount] = useState(0);

  const handleOnChange = (data) => {

    const tagRegex = /<[^>]+>/g;
    const textContent = data.replace(tagRegex, "");
    const clearText =  textContent.trim();
    const lengthChar = clearText.length;

    setCharCount(lengthChar);

    props.onChange(data);
  };

  return (
    <>
      <SunEditor
        hideToolbar={true}
        setContents={props.setContents}
        defaultValue={props.defaultValue}
        onChange={handleOnChange}
        setOptions={{
          height: 160,
          mode: "balloon-always",
          buttonList: [
            ["link"]
          ],
          charCounter: true,
          maxCharCount: 500,
          charCounterType: "char",
          linkNoPrefix: true,
          linkProtocol: "https://"
        }}
        placeholder="Mau cerita tentang apa hari ini?"
        setDefaultStyle="font-family: inherit; font-size: 1.125rem; line-height: 1.75rem; border: none; border-color: none; border-radius: 1rem; background-color: rgb(223 239 252);"
      />

      <div className="flex items-end justify-end mb-[10px] mt-2">
        <p className="font-brandon text-[11px]">{charCount || 0}/500</p>
      </div>
      
      <style>
        {`
          .sun-editor {
            border: none;
          }

          .dalems ::placeholder {
            color: red;
          }
        `}
      </style>
    </>
  );
};

export default RichEditor;
