import { getEventbyId, postEventInvite } from "Api/event";
import { getAllUserList } from "Api/user";
import ShareIcon from "Assets/Icons/share-icon.svg";
import { HappyFullIcon, LinkIcon } from "Assets/Images";
import { CircularLoader, Modal } from "Components";
import MultipleSelect from "Components/MultipelSelect";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "Themes";
import { imageLink } from "Utils/imageLink";
import { PreviewWrapper } from "./index.style";

const EventPreview = () => {
  const navigation = useNavigate();
  const alertComponent = useAlert();
  const { id } = useParams();

  const [dataDetail, setDataDetail] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  
  // function
  const handleNavigate = () => {
    navigation("/event");
  };

  const handleCategory = (data) => {

    let names = Array.isArray(data) && data?.map(item => item?.name);
    let result = names?.join(", ");

    return result;

  };

  const movePage = () => {
    navigation("/event");
    setIsShowModal(false);
  };

  const handleSubmitInvite = () => {
    const listIDArray = selectedOptions.map(data => data.value);
    postInviteEvent({ toUserIds: listIDArray });
  };

  // This is the function we wrote earlier
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }

  };

  const handleCopyClick = () => {
    const url = window.location.href;
    const domainSplit = url.split("/event")[0];

    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(`${domainSplit}/event/${id}`)
      .then(() => {
        // If successful, update the isCopied state value
        alertComponent.success("Tautan Acara Ceria telah berhasil disalin!");
      })
      .catch(() => {
        alertComponent.error("Maaf, Tautan Acara Ceria gagal untuk disalin!");
      });
  };

  const handlePostFeed = (hashtag) => {
    navigation(`/feeds/event/${hashtag.replace("#", "")}`);
  };

  // Function API
  const getDetailEvent = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await getEventbyId(id);
      setDataDetail(data);
      setIsLoading(false);
      
    } catch (error) {
      setIsLoading(false);
      alertComponent.error(error.response.data.message || "Error get event detail");
    }
  };

  const getAllUserData = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllUserList();
      if (data) {
        const customOption = data.map((user) => ({ label: user.fullname, value: user.id }));
        setOptions(customOption);
        setIsLoading(false);
      }
      
    } catch (error) {
      setIsLoading(false);
      alertComponent.error(error.response.data.message || "Error get all User");
    }
  };

  const postInviteEvent = async (payload) => {
    try {
      const { data } = await postEventInvite(id, payload);
      setIsShowModal(true);
      alertComponent.success("Invitation berhasil dikirim");
    } catch (error) {
      alertComponent.error(error.response.data.message || "Error submit invitation");
    }
  };
  
  // Component
  const renderHeader = () => (
    <div className="flex flex-row justify-between mb-4">
      <div className="mr-6">
        <p className="font-brandon_bold text-ileads-mainblue text-lg">{"Preview Acara Ceria"}</p>
        <div className="bg-ileads-yellow h-2 w-42" />
      </div>

      <button
        onClick={handleNavigate}
        className="bg-ileads-red text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap"
      >
        <span className="font-brandon_medium">Kembali Ke Acara Ceria</span>
      </button>
      
    </div>
  );

  const renderLoader = () => (
    <div className="flex items-center justify-center my-12">
      <CircularLoader size={30} color={Colors.yellow} unfilledColor={Colors.darkBlue} />
    </div>
  );
  
  useEffect(() => {
    getDetailEvent(id);
    getAllUserData();
  }, [id]);
  
  return (
    <>
      {renderHeader()}
      <PreviewWrapper>
        {
          isLoading ? renderLoader() : (
            <div className="flex justify-center flex-col">
              <div className="flex justify-center flex-col text-center items-center w-full">
                <p className="font-brandon_medium text-2xl border-b-4 border-ileads-yellow w-fit">Hore! Acara Ceria berhasil dibuat!</p>
                <p className="font-brandon text-xl" >Bagikan acara ceria ini ke rekan-rekan Anda atau undang rekan-rekan Anda untuk ikut di event ini sekarang!</p>
              </div>
              <div className="flex flex-col items-center mt-6 justify-center">
                <div className="flex gap-4 max-w-[80%]">
                  <div className="h-[340px] min-w-[255px] max-w-[255px] border flex justify-center items-center">
                    <img width={255} height={340} className="w-auto" alt="event-poster" src={imageLink(dataDetail.posterUrl)}/>
                  </div>
                  <div className="flex flex-col gap-1 min-w-0">
                    <p className="text-ileads-darkblue text-2xl font-brandon_medium truncate">{dataDetail.name}</p>
                    <p className="text-ileads-darkblue text-lg font-brandon_medium">
                      {
                              `${moment(dataDetail.startTime).format("DD MMMM YYYY, HH:mm")} ${dataDetail.timezone?.toUpperCase()} s/d 
                            ${moment(dataDetail.endTime).format("DD MMMM YYYY, HH:mm")} ${dataDetail.timezone?.toUpperCase()}`
                      }
                    </p>
                    {dataDetail.locationDetail && 
                            <p className="text-ileads-darkblue text-lg font-brandon_medium">
                              {dataDetail.locationDetail}
                            </p>
                    }
                    <span className="flex items-center gap-1">
                      <img className="h-[10px]" height="10px" width="auto" src={LinkIcon} alt="link-icon"/>
                      <a href={dataDetail.location} target="_blank" className="text-ileads-darkblue text-lg underline font-brandon_medium truncate" rel="noreferrer">{dataDetail.location}</a>
                    </span>
                    <p className="text-ileads-darkblue text-2xl font-brandon_medium border-b-4 border-ileads-yellow w-fit max-w-[322px] truncate">
                      {dataDetail.hashtag}
                    </p>
                    <div className="p-3 rounded-xl mt-2 bg-[#DFEFFC] border-ileads-mainblue border-2">
                      <p className="text-sm mb-3 whitespace-pre-line">{dataDetail.description}</p>
                      <p className="text-sm">
                        <span className="text-ileads-darkblue text-sm font-brandon_medium">
                          Kategori:
                        </span>
                        {" "} {dataDetail?.categories && handleCategory(dataDetail?.categories)}
                      </p>
                      <p className="text-sm">
                        <span className="text-ileads-darkblue text-sm font-brandon_medium">
                          Tipe Kegiatan:
                        </span>
                        {" "}{dataDetail.type?.name}
                      </p>
                    </div>
                    <div className="flex mt-2 gap-3">
                      <button
                        type="button" 
                        className="flex items-center gap-2 bg-ileads-lightblue text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                        onClick={handleCopyClick}
                      >
                        <span className="font-brandon_medium">Bagikan</span>
                        <img className="h-4" height={16} width="auto" src={ShareIcon} alt="link-icon"/>
                      </button>

                      <button
                        type="button" 
                        className="bg-ileads-green text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
                        onClick={() => handlePostFeed(dataDetail.hashtag)}
                      >
                        <span className="font-brandon_medium">Bagikan di Feed</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex flex-col">
                  <p className="font-brandon text-xl font-semibold">Undang</p>
                  <MultipleSelect
                    defaultValue={selectedOptions}
                    onChange={setSelectedOptions}
                    options={options}
                    isLoading={isLoading}
                    placeholder="Search"
                    isMulti
                  />
                  <button
                    type="button" 
                    disabled={Array.isArray(selectedOptions) && selectedOptions.length === 0}
                    onClick={handleSubmitInvite}
                    className={
                      `${Array.isArray(selectedOptions) && selectedOptions.length > 0 ? "bg-ileads-green cursor-pointer" : "bg-ileads-lightgrey cursor-not-allowed"} text-white text-center py-3 rounded-3xl px-6 mt-2 min-[67px] self-end`}
                  >
                    <span className="font-brandon_medium">Undang</span>
                  </button>
                </div>
              </div>
              <Modal
                show={isShowModal}
                onClose={() => setIsShowModal(false)}
                title={"Sukses!"}
                desc={"Anda telah berhasil mengundang rekan-rekan anda!"}
                icon={<img src={HappyFullIcon} width={"142px"} height="auto" alt="popup" />}
                buttons={
                  <div className="flex gap-3">
                    <button
                      type="button" 
                      className={"bg-ileads-green text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-fit"}
                      onClick={movePage}
                    >
                      Kembali ke Menu Sebelumnya
                    </button>
                  </div>
                }
              />
            </div>
          )
        }
      </PreviewWrapper>
    </>
  );
};

export default EventPreview;
