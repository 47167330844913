import { getListEvent, putEventJoin } from "Api/event";
import {
  CircularLoader,
  DropdownInput,
  Modal,
  Pagination,
  Underlined
} from "Components";
import CardEvent from "Components/CardEvent";
import { Constants } from "Constants";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { Colors } from "Themes";
import { DropdownOptions } from "Utils";
import { ImagePopUp } from "Utils/imagePopUp";
import { ContentWrapper } from "./index.style";

const Event = () => {
  const navigate = useNavigate();
  const alertComponent = useAlert();
  const [filter, setFilter] = useState({
    label: "",
    value: "newest"
  });
  const [tab, setTab] = useState("upcoming");
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    total_page: 1
  });

  const [isloadingButton, setIsloadingButton] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    action: "",
    id: 0,
    isOpen: false
  });

  const changePagination = (value, page) => {
    if (value === "next") {
      setPagination((data) => ({ ...data, page: data.page + 1 }));
    } else if (value === "prev") {
      setPagination((data) => ({ ...data, page: data.page - 1 }));
    } else if (value === "jump") {
      setPagination((data) => ({ ...data, page: page }));
    }

    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  };

  const changeTabs = (value) => {
    setTab(value);
    setPagination((data) => ({ ...data, page: 1 }));
  };

  const handleActionEvent = (event, id) => {
    setConfirmModal({
      action: event, 
      id: id,
      isOpen: true
    });
  };

  const handleJoinEvent = async (boolean) => {
    const payload = {
      join: boolean
    };

    setIsloadingButton(true);
    await putJoinEvent(confirmModal.id, payload);

  };

  const handleSubmitModal = (action) => {
    switch (action) {
    case "join-event":
      return handleJoinEvent(true);
    case "unjoin-event":
      return handleJoinEvent(false);
    case "join-success":
      return window.location.reload();
    case "unjoin-success":
      return window.location.reload();
    default:
      return console.log("No action");
    }
  };

  const handleListEvent = () => {
    const params = {
      page: pagination.page
    };

    if (tab === "finished") params.isFinished = true;
    if (tab === "upcoming") params.isUpcoming = true;
    if (tab === "myevent") params.isMe = true;

    if (filter.value === "newest") {
      params.sort = "event_created_at",
      params.order = "desc";
    }

    if (filter.value === "oldest") {
      params.sort = "event_created_at",
      params.order = "asc";
    }

    if (filter.value === "a-z") {
      params.sort = "name",
      params.order = "asc";
    }

    if (filter.value === "z-a") {
      params.sort = "name",
      params.order = "desc";
    }

    getEvents(params);
  };

  useEffect(() => {
    
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });

    handleListEvent();
  }, [pagination.page, tab, filter]);

  const getEvents = async (params) => {
    setIsLoading(true);
    try {
      const res = await getListEvent(params);
      setPagination(res.pagination);
      setEvents(res.data);
    } catch (error) {
      setEvents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const putJoinEvent = async (id, payload) => {
    try {
      
      const { data } = await putEventJoin(id, payload);

      if (payload.join) {
        alertComponent.success("Berhasil ikut acara ceria!");
        handleListEvent();
        setConfirmModal({ action: "join-success", isOpen: true });
      } else {
        alertComponent.success("Batal mengikuti acara ceria!");
        handleListEvent();
        setConfirmModal({ action: "unjoin-success", isOpen: true });
      }

      setIsloadingButton(false);

    } catch (error) {
      setConfirmModal({ action: "", isOpen: false });
      setIsloadingButton(false);
      alertComponent.error(error.response.data.message || "Error join event");
    }
  };

  const renderLoader = () => (
    <div className="flex items-center justify-center my-12">
      <CircularLoader
        size={30}
        color={Colors.yellow}
        unfilledColor={Colors.darkBlue}
      />
    </div>
  );

  return (
    <>
      <div className="flex flex-row justify-between mb-4">
        <div className="mr-6">
          <p className="font-brandon_bold text-ileads-mainblue text-lg">
            Acara Ceria
          </p>
          <div className="bg-ileads-yellow h-2 w-42" />
        </div>
        <button
          onClick={() => navigate("/homepage")}
          className="bg-ileads-red text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap"
        >
          <span className="font-brandon_medium">Kembali</span>
        </button>
      </div>

      <ContentWrapper>
        <div className="w-full flex justify-center">
          <Underlined className="mx-auto">List Acara Ceria</Underlined>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            type="button"
            className="bg-ileads-green text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-fit h-fit"
            onClick={() => navigate("/event/create")}
          >
            Buat Acara Ceria
          </button>

          <div className="flex gap-4 text-lg">
            <button
              type="button"
              className={`font-brandon_medium p-0 border-b-[8px] ${
                tab === "upcoming" ? "border-ileads-yellow" : "border-white"
              } h-fit`}
              onClick={() => changeTabs("upcoming")}
            >
              Acara Mendatang
            </button>
            <button
              type="button"
              className={`font-brandon_medium p-0 border-b-[8px] ${
                tab === "finished" ? "border-ileads-yellow" : "border-white"
              } h-fit`}
              onClick={() => changeTabs("finished")}
            >
              Acara Tuntas
            </button>
            <button
              type="button"
              className={`font-brandon_medium p-0 border-b-[8px] ${
                tab === "myevent" ? "border-ileads-yellow" : "border-white"
              } h-fit`}
              onClick={() => changeTabs("myevent")}
            >
              Acara Anda
            </button>
          </div>

          <div className="w-40">
            <DropdownInput
              type={"secondary"}
              options={DropdownOptions.convertToOptions(
                Constants.FilterSort,
                "label",
                "value"
              )}
              value={filter?.label}
              onSelect={(objValue) => {
                setFilter(objValue);
              }}
              placeholder={"Urutkan acara"}
              wrapperClassName="w-full h-12"
              fontSize={16}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 mt-6">
          {isLoading
            ? renderLoader()
            : Array.isArray(events) &&
              events.length > 0 &&
              events.map((eventData) => (
                <CardEvent 
                  isLoading={isloadingButton}
                  key={eventData.id} 
                  {...eventData} 
                  handleActionEvent={handleActionEvent}
                />
              ))}
        </div>

        <Pagination
          className="mx-auto mt-6"
          current={pagination.page}
          max={pagination.total_page}
          onNext={() => changePagination("next")}
          onPrev={() => changePagination("prev")}
          onJump={(value) => changePagination("jump", value)}
        />

        <Modal
          show={confirmModal.isOpen}
          onClose={() =>
            setConfirmModal((data) => ({ ...data, isOpen: false }))
          }
          title={
            confirmModal.action !== ""
              ? Constants.ModalConfirm.filter(
                (data) => data.status === confirmModal.action
              )[0]?.title
              : "title"
          }
          desc={
            confirmModal.action !== ""
              ? Constants.ModalConfirm.filter(
                (data) => data.status === confirmModal.action
              )[0]?.description
              : "dec"
          }
          icon={ImagePopUp(confirmModal.action)}
          buttons={
            <div className="flex gap-3">
              {["join-event", "unjoin-event"].includes(
                confirmModal.action
              ) && (
                <button
                  type="button"
                  className="bg-ileads-danger-3 text-white text-center py-3 rounded-btn px-6 whitespace-nowrap w-32"
                  onClick={() =>
                    setConfirmModal((data) => ({ ...data, isOpen: false }))
                  }
                >
                  Tidak
                </button>
              )}

              <button
                type="button"
                className={`bg-ileads-green text-white text-center py-3 rounded-btn px-6 whitespace-nowrap ${
                  confirmModal.action === "join-success" ||
                  confirmModal.action === "unjoin-success"
                    ? "w-fit"
                    : "w-32"
                }`}
                onClick={() => handleSubmitModal(confirmModal.action)}
              >
                {confirmModal.action === "join-success" ||
                confirmModal.action === "unjoin-success"
                  ? "Kembali ke List Acara Ceria"
                  : "Iya"}
              </button>
            </div>
          }
        />
      </ContentWrapper>
    </>
  );
};

export default Event;
