import { HappyIcon, HeartRedIcon, LikeIcon, LinkIcon, SadIcon } from "Assets/Images";
import Image from "Components/Image";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { imageLink } from "Utils/imageLink";

const CardEventFeed = (props) => {

  const navigation = useNavigate();

  const IconReaction = (react) => {
    switch (react) {
    case "love":
      return HeartRedIcon;
    case "like":
      return LikeIcon;
    case "happy":
      return HappyIcon;
    case "sad":
      return SadIcon;
    
    default:
      return HeartRedIcon;
    }
  };

  return (
    <div className="flex flex-col items-center justify-start">
      <div className={"flex gap-4 max-w-[90%] p-4 rounded-xl"}>
        <div className={"h-[340px] max-h-[340px] min-w-[255px] max-w-[255px] border flex justify-center items-center overflow-hidden bg-white"}>
          <img width={255} height={340} className="w-auto" alt="event-poster" src={imageLink(props.posterUrl)}/>
        </div>
        <div className="flex justify-start text-start flex-col gap-1 min-w-0 max-w-[400px]">
          <p className={"text-ileads-darkblue text-2xl font-brandon_medium border-b-4 border-ileads-yellow w-fit max-w-[322px] truncate"}>
            {props.hashtag ? props.hashtag : ""}
          </p>

          <div className="rounded-xl overflow-hidden">
            <p className={"text-sm whitespace-pre-line"}>
              {props.description?.length > 120 ?
                  `${props.description.substring(0, 120)}...` : props.description
              }
            </p>
          </div>

          <div className="flex gap-2">
            {
              Array.isArray(props.count_reaction) && props.count_reaction.map(data => (
                <div className="flex items-center w-fit h-fit" key={data.reaction}>
                  <p className="text-lg font-brandon_medium mr-1">{data.count}</p>
                  <Image width={24} height={24} src={IconReaction(data.reaction)} alt={data.reaction}/>
                </div>
              ))
            }
          </div>
          
          <p className={"text-ileads-darkblue text-2xl font-brandon_medium"}>{props.name}</p>
          <p className={"text-ileads-darkblue text-lg font-brandon_medium"}>
            {
                  `${moment(props.startTime).format("DD MMMM YYYY, HH:mm")} ${props.timezone?.toUpperCase()} s/d 
                ${moment(props.endTime).format("DD MMMM YYYY, HH:mm")} ${props.timezone?.toUpperCase()}`
            }
          </p>
          {props.locationDetail && 
                <p className={"text-ileads-darkblue text-lg font-brandon_medium"}>
                  {props.locationDetail}
                </p>
          }
          <span className="flex items-center gap-1">
            <img style={{ height: "10px", width: "auto" }} src={LinkIcon} alt="link-icon"/>
            <a href={props.location ? props.location : ""} target="_blank" className={"text-ileads-darkblue text-lg underline font-brandon_medium truncate"} rel="noreferrer">{props.location}</a>
          </span>
          
          <div className="flex mt-2 justify-between w-full">
            <button
              type="button" 
              className="flex items-center gap-2 bg-ileads-lightblue text-white text-center py-3 rounded-3xl px-6 whitespace-nowrap"
              onClick={() => navigation(`/event/${props.id}`)}
            >
              <span className="font-brandon_medium">Lihat Detail</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEventFeed;
